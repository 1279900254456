<template>
	<footer class="footer place-items-center fixed bottom-0 z-50 h-7 border-t border-solid border-lightgrey bg-white" :class="{ 'w-40': isSidebarToggle, 'w-10': !isSidebarToggle }">
		<div class="flex justify-center items-center p-2">
			<h1 class="text-xs">© {{year}} Crystalline Software Technologies Pvt. Ltd</h1>
		</div>
	</footer>
</template>
<style scoped>
.w-40 {
	width: calc(100% - 170px);
}
.w-10 {
	width: calc(100% - 2.5rem);
}
</style>
<script lang="ts">
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import { emitter } from '../main';
export default defineComponent({
	data():any {
		return {
			isSidebarToggle: true,
			year:''
		};
	},
	computed: {
		...mapGetters({toggleState:'toggle'})
	},
	mounted() {
		const d = new Date();
		this.year = d.getFullYear();
		emitter.on('toggle-sidebar', (isToggle: any) => {
			this.isSidebarToggle = isToggle;
		});
		this.isSidebarToggle = this.toggleState;
	}
});
</script>