<template>
    <div id="body-container">
        <div>
            <NavBar />
            <div class="flex" @click="closeApps()">
                <div :class="{ 'w-40': isTogglingData, 'w-10': !isTogglingData }">
                    <div class="fixed bg-primary sidenav flex flex-col justify-between h-full z-50" :class="{ 'w-40': isTogglingData, 'w-10': !isTogglingData }">
                        <ul>
                            <li @click="navigate('audit', 'audit')" class="cursor-pointer hover:bg-white svg-icon pl-2.5" :class="selectedOptionName === 'audit' ? 'bg-white text-black font-bold a svg-icon123' : 'text-lightgrey font-normal'">
                                <div>
                                    <a class="flex navchild items-center hover:text-black py-1">
                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g id="Audit">
                                            <g id="Group">
                                            <path id="Vector" d="M12.3359 1.14453V4.44563H15.637L12.3359 1.14453Z" fill="#E9E9E9"/>
                                            <path id="Vector_2" d="M12.0898 4.9375C11.9538 4.9375 11.8438 4.8275 11.8438 4.69141V1H2.98438C2.44026 1 2 1.44026 2 1.98438V15.7656C2 16.3097 2.44026 16.75 2.98438 16.75H14.7969C15.341 16.75 15.7812 16.3097 15.7812 15.7656V4.9375H12.0898ZM4.70703 5.42969H8.64453C8.78062 5.42969 8.89062 5.53969 8.89062 5.67578C8.89062 5.81187 8.78062 5.92188 8.64453 5.92188H4.70703C4.57094 5.92188 4.46094 5.81187 4.46094 5.67578C4.46094 5.53969 4.57094 5.42969 4.70703 5.42969ZM13.0742 13.5508H4.70703C4.57094 13.5508 4.46094 13.4408 4.46094 13.3047C4.46094 13.1686 4.57094 13.0586 4.70703 13.0586H13.0742C13.2103 13.0586 13.3203 13.1686 13.3203 13.3047C13.3203 13.4408 13.2103 13.5508 13.0742 13.5508ZM13.0742 12.0742H4.70703C4.57094 12.0742 4.46094 11.9642 4.46094 11.8281C4.46094 11.692 4.57094 11.582 4.70703 11.582H13.0742C13.2103 11.582 13.3203 11.692 13.3203 11.8281C13.3203 11.9642 13.2103 12.0742 13.0742 12.0742ZM13.0742 10.5977H4.70703C4.57094 10.5977 4.46094 10.4877 4.46094 10.3516C4.46094 10.2155 4.57094 10.1055 4.70703 10.1055H13.0742C13.2103 10.1055 13.3203 10.2155 13.3203 10.3516C13.3203 10.4877 13.2103 10.5977 13.0742 10.5977ZM13.0742 9.12109H4.70703C4.57094 9.12109 4.46094 9.01109 4.46094 8.875C4.46094 8.73891 4.57094 8.62891 4.70703 8.62891H13.0742C13.2103 8.62891 13.3203 8.73891 13.3203 8.875C13.3203 9.01109 13.2103 9.12109 13.0742 9.12109ZM13.0742 7.64453H4.70703C4.57094 7.64453 4.46094 7.53453 4.46094 7.39844C4.46094 7.26235 4.57094 7.15234 4.70703 7.15234H13.0742C13.2103 7.15234 13.3203 7.26235 13.3203 7.39844C13.3203 7.53453 13.2103 7.64453 13.0742 7.64453Z" fill="#E9E9E9"/>
                                            </g>
                                            </g>
                                        </svg>
                                        <div class="item-name leading-5 text-sm pl-2" :class="selectedOptionName == 'audit' ? 'text-black font-bold' : 'text-listIconColor font-normal'" v-if="isTogglingData">Audit</div>
                                    </a>
                                </div>
                            </li>
                        </ul>

                        <ul class="bottomUl mb-16">
							<li class="relative">
                                <hr class="mb-4" />
                                <img src="@/assets/leftarrow.svg" class="h-8 ml-32 icon leftarrow absolute cursor-pointer" alt="" @click="toggleSideMenuBar()" v-if="isSidebarToggle" />
                                <img src="@/assets/rightarrow.svg" class="h-8 icon ml-2 rightarrow absolute cursor-pointer" alt="" @click="toggleSideMenuBar()" v-else />
                            </li>
                            <li class="mt-2">
                                <a @click="triggerNavToAdminSettings()" class="flex navchild items-center" :class="{ 'pl-1 py-1': isSidebarToggle, 'pl-2.5 py-1': !isSidebarToggle }">
                                    <img src="@/assets/settings.svg" class="icon" :class="{ 'px-1.5': isSidebarToggle, '': !isSidebarToggle }" />
                                    <div class="item-name leading-5 text-sm text-lightgrey" v-if="isSidebarToggle">Settings</div>
                                </a>
                            </li>
						</ul>
                        <div class="fixed shadow z-50 bg-white w-40 px-2 py-1 min-h-30 flex flex-col justify-center" v-if="appsVisible">
                            <div class="flex flex-row items-center cursor-pointer">
                                <div class="flex flex-row w-2/4 items-center cursor-pointer" @click="triggerNavigation()">
                                    <img src="../assets/training.svg" alt="" class="icn h-8 w-8" />
                                    <div class="nav-item-label-container ml-4">
                                        <div class="text-sm font-semibold">Training</div>
                                        <div class="active-border"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="content flex-1 w-full h-screen overflow-y-scroll" :class="{ toggledstate: isTogglingData }">
                    <div class="page">
                        <slot />
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
/* .shadow {
	--tw-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.25);
	box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
} */
.page{
	margin-bottom: 100px;
}
.leftarrow,
.rightarrow {
	height: 1.25rem;
	width: 1.25rem;
	margin-top: -27px;
}
.a:hover{
	color:black;
	font-weight: 400;
}
.svg-icon:hover {
    color:black;
}
.svg-icon123 div a svg path {
    fill: #152A43;
}
.svg-icon:hover path {
    fill: #152A43; /* Change the color to your desired hover color */
}
.svg-Image:hover svg path{
    fill: #152A43;
}
/* .sideMenuBarStyle:focus {
	color: #000000;
} */

/* width */
/* ::-webkit-scrollbar {
	width: 10px;
} */

/* Track */
/* ::-webkit-scrollbar-track {
	box-shadow: inset 0 0 3px grey;
	border-radius: 10px;
} */

/* Handle */
/* ::-webkit-scrollbar-thumb {
	background: gray;
	border-radius: 5px;
} */

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
	background: gray;
} */
</style>
<script lang="ts">
import { mapGetters } from 'vuex';
import NavBar from '@/components/navbar.vue';
import Footer from '../components/footer.vue';
import { emitter } from '../main';
import { defineComponent } from 'vue';

export default defineComponent({
    data(): any {
        return {
            isSidebarToggle: true,
            isTogglingData: true,
            manage: false,
            rootControls: false,
            appsVisible: false,
            sideNavSelected: '',
            selectedOptionName: '',
            passingRouteName:'',
            menus: [
				{
                    optionName: 'audit',
                    optionRoute: 'audit',
                    // defaultoptionImage: require('@/assets/Informationpage.svg'),
                    // colouredoptionImage: require('@/assets/InformationColored.svg'),
                    displayOptionName: 'Audit'
                }, 
            ]
        };
    },

    components: {
        NavBar,
        Footer
    },
    computed: {
        currentRouteName() {
            return this.$route.name;
        },
		...mapGetters({ toggleState:'toggle' })
	},
    methods: {
        toggleInfo(arg1: any) {
            this.toggleIndicator = arg1;
        },
        logout() {
            this.$http.post(`${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_MONITOR_API_PATH}/api/auth/logout`).then(() => {
                this.$router.push({ name: 'login' });
                localStorage.removeItem('AUTH_ID');
            });
        },
        toggleSideMenuBar() {
            this.isSidebarToggle = !this.isSidebarToggle;
            emitter.emit('toggle-sidebar', this.isSidebarToggle);
        },
        triggerNavToAdminSettings() {
            window.location.href = `${process.env.VUE_APP_AUTH_WEBAPP_URL}/session?returnTo=${process.env.VUE_APP_ADMIN_WEBAPP_URL}`;
        },
        navigate(name: any, routerOption: any) {
            this.$store.dispatch('selectedRoute', name);
            this.$router.push({ name: routerOption });
        },
        triggerNavigation() {
            window.location.href = `${process.env.VUE_APP_AUTH_WEBAPP_URL}/session?returnTo=${process.env.VUE_APP_TRAINING_WEBAPP_URL}`;
        },
        closeApps(){
			this.appsVisible=false;
			emitter.emit('apps', this.appsVisible);
		},
    },
    mounted() {
    	this.passingRouteName = this.currentRouteName;
        this.selectedOptionName = this.passingRouteName;
        console.log('selectedOptionName', this.selectedOptionName);
        emitter.on('toggle-sidebar', (isToggle) => {
			this.isTogglingData = isToggle;
		});
        emitter.on('apps-sidemenubar', (isToggle) => {
            this.appsVisible = isToggle;
        });
    }
});

</script>

  