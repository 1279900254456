<template>
	<div id="body-container">
		<navbar />
		<div class="flex" @click="closeApps()">
			<div :class="{ 'w-40': isToggling, 'w-10': !isToggling }">
				<sidemenubar />
			</div>
			<div class="content flex-1 w-full h-screen overflow-y-scroll" :class="{ toggledstate: isToggling }"  @scroll="handleScroll">
				<div class="page">
					<slot />
				</div>
				<Footer />	
			</div>
		</div>
	</div>
</template>
<style  scoped>
.page{
	margin-bottom: 75px;
}
</style>
 <script lang="ts">
import navbar from '@/components/navbar.vue';
import sidemenubar from '@/components/sidemenubar.vue';
import Footer from '@/components/footer.vue'
import { defineComponent } from 'vue';
import { emitter } from '../main';
import { mapGetters } from 'vuex';
export default defineComponent({
	components: {
		navbar,
		sidemenubar,
		Footer
	},
	data() {
		return {
			isToggling: true,
			appsVisible:false,
		};
	},
	computed:{
		...mapGetters({ selectedOptionName: 'val',toggleState:'toggle' })
	},
	methods:{
		closeApps(){
			this.appsVisible=false;
		emitter.emit('apps', this.appsVisible);
		},
		handleScroll() {
			if (this.$route.path.includes('/audit')){
				emitter.emit('close-dropdown'); // Emit event to close dropdown on scroll
			}
		}
	},

	mounted() {
		this.isToggling = this.toggleState;
		emitter.on('toggle-sidebar', (isToggle: any) => {
			this.isToggling = isToggle;
		});
	}
});
</script>