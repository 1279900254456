import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import defaultLayout from "@/layouts/defaultLayout.vue";
import emptyLayout from "@/layouts/emptyLayout.vue";
import adminlayout from "@/layouts/adminLayout.vue";
import dashboard from "@/features/dashboard.vue";
import auditEditPage from "@/pages/auditMapPage.vue";
import controlEdit from "@/pages/controlEdit.vue";
import auditFrameworkMapPage from "@/pages/auditFrameworkMap.vue";
import audit from "@/pages/audit.vue";
import addNewAudit from "@/pages/addNewAudit.vue";
import auditControls from '@/pages/auditControls.vue';
import startAudit from "@/pages/startAudit.vue";
import viewAudit from "@/pages/viewAudit.vue";
import myTasks from '@/pages/myTasks.vue';
import myFindings from '@/pages/myFindings.vue';
import findingsInfo from '@/pages/findingsInfo.vue';
import auditFindings from '@/pages/auditFindings.vue';
import findingTaskEdit from '@/pages/findingTaskEdit.vue';
import findingTask from '@/pages/findingTask.vue'



const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'default',
        meta: {
            title: "Audit",
            layout: defaultLayout,
        },
        redirect: { name: 'audit' }
    },
    {
        path: '/admin',
        name: 'default-admin',
        meta: {
            title: "Audit",
            layout: adminlayout,
        },
        redirect: { name: 'audit' }
    },
    {
        path: "/audit",
        name: "audit",
        meta: {
            title: "Audit",
            layout: defaultLayout,
        },
        component: audit,
        // beforeEnter: (to, from, next) => {
        //     securityOfficerNavigationGuard(to, from, next)
        //   },
       
    },
    {
        path: "/org/audit/create",
        name: "add-audit",
        meta: {
            title: "Create-Audit",
            layout: defaultLayout,
        },
        component: addNewAudit,
        // beforeEnter: (to, from, next) => {
        //     securityOfficerNavigationGuard(to, from, next)
        //   },
       
    },
    {
        path: "/org/audit/:auditId/task/:taskId/edit",
        name: "controlEdit",
        meta: {
            title: "controlEdit",
            layout: defaultLayout,
        },
        component: controlEdit,
        // beforeEnter: (to, from, next) => {
        //     securityOfficerNavigationGuard(to, from, next)
        //   },
       
    },
    {
        path: "/org/audit/finding/:findingId/findingInformation",
        name: "findingsInfo",
        meta: {
            title: "findingsInfo",
            layout: defaultLayout,
        },
        component: findingsInfo,
        // beforeEnter: (to, from, next) => {
        //     securityOfficerNavigationGuard(to, from, next)
        //   },
       
    },
    {
        path: "/org/audit/:auditId/edit",
        name: "edit-audit",
        meta: {
            title: "Edit-Audit",
            layout: defaultLayout,
        },
        component: addNewAudit,
        // beforeEnter: (to, from, next) => {
        //     securityOfficerNavigationGuard(to, from, next)
        //   },
       
    },
    {
        path: "/org/audit/:auditId/controls",
        name: "audit-controls",
        meta: {
            title: "Audit-Controls",
            layout: defaultLayout,
        },
        component: auditControls,
        // beforeEnter: (to, from, next) => {
        //     securityOfficerNavigationGuard(to, from, next)
        //   },
       
    },
    {
        path: "/org/audit/:auditId/startAudit",
        name: "start-audit",
        meta: {
            title: "Start-Audit",
            layout: defaultLayout,
        },
        component: startAudit,
        // beforeEnter: (to, from, next) => {
        //     securityOfficerNavigationGuard(to, from, next)
        //   },
       
    },
    {
        path: "/org/audit/:auditId/viewAudit",
        name: "view-audit",
        meta: {
            title: "View-Audit",
            layout: defaultLayout,
        },
        component: viewAudit,
        // beforeEnter: (to, from, next) => {
        //     securityOfficerNavigationGuard(to, from, next)
        //   },
       
    },
    {
        path: "/org/audits/myTasks",
        name: "myTasks",
        meta: {
            title: "myTasks",
            layout: defaultLayout,
        },
        component: myTasks,
        // beforeEnter: (to, from, next) => {
        //     securityOfficerNavigationGuard(to, from, next)
        //   },
       
    },
    {
        path: "/org/audits/myFindings",
        name: "myFindings",
        meta: {
            title: "myFindings",
            layout: defaultLayout,
        },
        component: myFindings,
        // beforeEnter: (to, from, next) => {
        //     securityOfficerNavigationGuard(to, from, next)
        //   },
       
    },
    {
        path: "/org/audit/findingTasks",
        name: "findingTask",
        meta: {
            title: "findingTask",
            layout: defaultLayout,
        },
        component: findingTask,
        // beforeEnter: (to, from, next) => {
        //     securityOfficerNavigationGuard(to, from, next)
        //   },
       
    },
    {
        path: "/org/audit/:auditId/auditFindings",
        name: "auditFindings",
        meta: {
            title: "auditFindings",
            layout: defaultLayout,
        },
        component: auditFindings,
        // beforeEnter: (to, from, next) => {
        //     securityOfficerNavigationGuard(to, from, next)
        //   },
       
    },
    {
        path: "/org/audit/finding/:findingId/task/:taskId/findingsTask",
        name: "findingTaskEdit",
        meta: {
            title: "findingTaskEdit",
            layout: defaultLayout,
        },
        component: findingTaskEdit,
        // beforeEnter: (to, from, next) => {
        //     securityOfficerNavigationGuard(to, from, next)
        //   },
       
    },

]
const router = createRouter({
    history: createWebHistory('/'),
    routes,
});

router.beforeEach(async (to, from, next) => {
    store.state.isLoading = true;
    if(to.meta.title){
        document.title=`Audit - ${to.meta.title}`
    }
    else{
        document.title=`Audit`
    }
    console.log('Router check triggered', to)
    if ( to.path == '/admin' ||  to.path == '/' || to.path=='/dashboard' ||to.path=='/audit') {
        console.log('Nav to default route with', to.query.session)
        if (to.query.session) {
            localStorage.setItem('AUTH_ID', to.query.session.toString());
            await store.dispatch('getUserInfo').then((res) => {
                console.log('response', res.data[0]);
                if (!res.data[0]) window.location.href = `${process.env.VUE_APP_AUTH_WEBAPP_URL}/session?returnTo=${process.env.VUE_APP_AUDIT_WEBAPP_URL}`;
                else {
                    next();
                }
            })
        }
        await normalAuthenticationCheck(next);
    } else {
        await normalAuthenticationCheck(next);
    }
    // next();
});

router.afterEach(async (to, from, next) => {
    store.state.isLoading = false
});


async function normalAuthenticationCheck(next: any) {
    console.log('Inside normal check')
    if(!localStorage.getItem('AUTH_ID')) {
        console.log('AUTH ID NOT found')
        window.location.href = `${process.env.VUE_APP_AUTH_WEBAPP_URL}/session?returnTo=${process.env.VUE_APP_AUDIT_WEBAPP_URL}`;
    } else{
        const userInfo = store.getters.userInfo;
        console.log('USER INFO', userInfo)
        if(!userInfo.roles) {
            await store.dispatch('getUserInfo').then((res) => {
                next();
            });
        }
        else{
            next();
        }
    }
}



export default router;