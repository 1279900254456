<template>
    <div class="tableCornerRadiusForAudit mb-16 bg-white">
        <table>
            <thead>
                <th scope="col" v-for="columnName in columnNames" :key="columnName" :class="getColumnHeadWidth(columnName)" class="bg-white capitalize" @click="sort(columnName)">
                    <div class="flex">
                        <div>{{ columnName }}</div>
                        <div class="sort cursor-pointer" :class="className == columnName ? classSort : 'opacity-50'"></div>
                    </div>
                </th>
            </thead>
            <tbody v-if="sortedData.length == 0">
                <tr class="cursor-pointer empty rowHeight">
                    <td class="relative h-10" :colspan="columnNames.size">
                        <div class="flex justify-center"><p class="text-xs font-normal">No Records Found</p></div>
                    </td>
                </tr>
            </tbody>
            <tbody class="nonEmptyTable" v-else>
                <tr v-for="(row, index) in sortedData" :key="row" class="cursor-pointer rowHeight">
                    <td v-for="columnName in columnNames" :key="columnName" @mouseout="doFalse()" class="cursor-pointer relative">
                        <div v-if="columnName == 'auditee'">
							<!-- <select class="inputboxstyling" v-model="row['auditee']" 
                            :class="{
                                'is-invalid': !v$.sortedData.$each.$response.$data[index].auditee.required
                                }"
                            >
                             	<option value="" selected disabled hidden>Please select one</option>
                                <option v-for="obj in optionsUserArray" :key="obj.id" :value="obj.value">{{ obj.label }}</option>
                          	</select> -->
                            <multiselect
								v-model="row['auditee']"
								:options="auditeeOptions"
								:showNoOptions="false"
								@search-change="asyncFind"
								:limit-text="limitText"
								:multiple="false"
								:close-on-select="true"
								:clear-on-select="true"
								open-direction="bottom"
								label="label"
								track-by="value"  
                                :hide-selected="true"
                                @select="checkAfterRequired('check')"  
								:searchable="true"
								:hideArrow="false"
								placeholder="Auditee"
								class="custom-multiselect userSelect"
								:class="{
									'is-invalid borderRed': validationErrors[index]?.auditee || validationErrors[index]?.sameAuditorAuditee,
                                    'has-options': auditeeOptions && auditeeOptions.length > 0,
                                    'no-options': !auditeeOptions.length &&  searchValue !== '',
								}"
							>
                                <template v-slot:noResult>
									<span class="text-xs">No Members Found</span>
								</template>
							</multiselect>
                             <div class="text-red-500">		
                                <div class="error-text" v-if="validationErrors[index]?.auditee">Required</div>
                                <div class="error-text" v-if="validationErrors[index]?.sameAuditorAuditee">Auditee and Auditor cannot be the same</div>
                            </div>
						</div>
                        <div v-else-if="columnName == 'auditor'">
							<!-- <select class="inputboxstyling" v-model="row['auditor']"
                            :class="{
                                'is-invalid': !v$.sortedData.$each.$response.$data[index].auditor.required
                                }"
                            >
                             	<option value="" selected disabled hidden>Please select one</option>
                                <option v-for="obj in optionsUserArray" :key="obj.id" :value="obj.value">{{ obj.label }}</option>
                          	</select> -->
                            <multiselect
								v-model="row['auditor']"
								:options="auditorOptions"
								:showNoOptions="false"
								@search-change="asyncFind1"
								:limit-text="limitText"
								:multiple="false"
								:close-on-select="true"
								:clear-on-select="true"
								open-direction="bottom"
                                @select="checkAfterRequired('check')"
								label="label"
                                :hide-selected="true"
								track-by="value"    
								:searchable="true"
								:hideArrow="false"
								placeholder="Auditor"
								class="custom-multiselect userSelect"
								:class="{
									'is-invalid borderRed': validationErrors[index]?.auditor || validationErrors[index]?.sameAuditorAuditee,
                                    'has-options': auditorOptions && auditorOptions.length > 0,
                                    'no-options': !auditorOptions.length &&  searchValue !== '',
								}"
							>
                                <template v-slot:noResult>
									<span class="text-xs">No Members Found</span>
								</template>
							</multiselect>
                            <div class="text-red-500">		
                                <div class="error-text" v-if="validationErrors[index]?.auditor">Required</div>
                                <div class="error-text" v-if="validationErrors[index]?.sameAuditorAuditee">Auditee and Auditor cannot be the same</div>
                            </div>
						</div>
                        <div v-else-if="columnName=='entity'">
                            <div class="table-ellipsis">
                                <div @mousemove="toolTip(columnName, $event, index, row, 'text')">
                                    {{getLocationName(row[columnName])  }}
                                </div>
                            </div>
                            <div v-if="pop == true && sortedData[tipIndex][columnName] == tipText && tipColumn == columnName && tipIndex == index">
                                <tool-tip :columnName="columnName" :tipText="tipText" :styling="styling"></tool-tip>
                            </div>
                        </div>
                        <div v-else-if="columnName=='team'">
                            <div class="table-ellipsis">
                                <div @mousemove="toolTip(columnName, $event, index, row, 'text')">
                                    {{getTeamName(row[columnName])  }}
                                </div>
                            </div>
                            <div v-if="pop == true && sortedData[tipIndex][columnName] == tipText && tipColumn == columnName && tipIndex == index">
                                <tool-tip :columnName="columnName" :tipText="tipText" :styling="styling"></tool-tip>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <pagination :dynamicPagination="dynamicPagination" @currentPageUpdate="paginationUpdations" :entityId="entityId" :sortedData="sortedData"></pagination>
    </div>
    
</template>
<style scoped>
tr:last-child {
    border-bottom: 1px solid #e9e9e9 ;
}
.w-40 {
    width: 155px;
}
.ellipsis-container {
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    line-height: 1.5em;
    max-height: 3em;
}
.table-ellipsis {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.width20{
    width : 20%
}
.width30{
    width : 30%
}
</style>

<script lang="ts">
import { emitter, toast } from '@/main';
import Multiselect from 'vue-multiselect';
import _ from 'lodash';
import { defineComponent } from 'vue';
import toolTip from '@/components/toolTip.vue';
import pagination from '@/components/pagination.vue';
import useVuelidate from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';
import { mapGetters } from 'vuex';

export default defineComponent({
    props: ['tableRowData', 'headersData','locations','departments','userArray'],
    data(): any {
        return {
            v$:useVuelidate(),
            tableHead: [],
            tableRow: [],
            searchTableRow: [],
            entityId: '',
            visibility: false,
            className: '',
            classSort: '',
            currentSort: 'name',
            currentSortDir: 'asc',
            showDropDown: false,
            showBlock: false,
            validationErrors: [],

            currentPage: '',
            pageSize: '',
            dynamicPagination: '',

            pop: false,
            styling: { left: '', bottom: '' },
            tipText: '',
            tipColumn: '',
            tipIndex: '',
            filters: {},
            users: [],
            optionsUserArray: [],
            selectedRowIndex: '',
            action:'',
            auditeeOptions:[],
            auditorOptions:[],
            userInfoFilteredDetails : {},
            searchValue:''
        };
    },
    validations(){
		return{
            sortedData: {
                $each: helpers.forEach({
                    auditee:{required},
                    auditor:{required}
                })
            }
        }
    },
    components: {
        toolTip,
        Multiselect,
        pagination
    },
    async mounted() {
        this.initialize();
        await this.getAllUsers();

        await this.checkAfterRequired('check')
        
        emitter.on('visible', (isBoolean: any) => {
            this.visibility = isBoolean;
            this.visibility == false ? (this.filters = {}) : '';
        });
        
        emitter.off('checkAuditValidation')
        emitter.on('checkAuditValidation', (action:any) => {
            this.action = action;
            this.v$.sortedData.$touch();
            console.log('cam intooooo checkAuditValidationcheckAuditValidation')
            if(!this.v$.sortedData.$invalid){
                this.checkAfterRequired('save')
            }else{
                toast.error(`Validation Error`, {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            }
        });
        
         this.userInfoFilteredDetails = {
              label: this.userInfo.name,
              value: this.userInfo._id,
            };
            console.log('MOUNTED',this.userInfoFilteredDetails)
    },
    computed: {
        ...mapGetters({ userInfo: "userInfo" }),
        sortedData: function (): any {
            return [...this.tableRow]
                .filter((row: any) => {
                    for (const key in this.filters) {
                        const inputValue = this.filters[key];
                        if (inputValue !== '' && row[key]) {
                            if (Number.isInteger(row[key])) {
                                if (row[key] !== Number(inputValue)) {
                                    return false;
                                }
                            } else {
                                if (!row[key].toLowerCase().includes(inputValue.toLowerCase())) {
                                    return false;
                                }
                            }
                        }
                    }
                    return true;
                })
                .sort((a, b) => {
                    let modifier = 1;
                    if (this.currentSortDir === 'desc') modifier = -1;
                    if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
                    if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
                    return 0;
                })
                .filter((row, index) => {
                    this.dynamicPagination = index + 1;
                    let start = (this.currentPage - 1) * this.pageSize;
                    let end = this.currentPage * this.pageSize;
                    if (index >= start && index < end) return true;
                });
        },
        columnNames: function (): any {
            const names = new Set();
            for (const key of this.headersData) {
                if (key != 'entityId' && key != '_id') names.add(key);
            }
            return names;
        }
    },
    watch: {
        visibility(newValue) {
            if (newValue == false) {
                this.initialize();
            }
        },
        tableRowData: {
            handler(newVal, oldVal) {
                this.tableRow = this.tableRowData;
                this.initialize();
                console.log('this.tableRowData[0]', this.tableRowData[0]);
            },
            deep: true
        }
    },
    methods: {
        getColumnHeadWidth(column : any){
             if (column == 'team'  || column=='entity') {
                return 'width20';
             }else if(column == 'auditee' || column=='auditor'){
                return 'width30'
             } 
        },
        limitText (count:any) {
			// return `and ${count} other countries`
			console.log('countcount',count)
		},
		asyncFind1(query:any){
            this.searchValue = '';
			if(query == ''){
				this.auditorOptions = []
			}else if(query.length > 0){
				this.auditorOptions = this.userArray.filter((obj:any)=>{return obj.label.toLowerCase().includes(query.toLowerCase())})
			}
		},
        asyncFind(query:any){
            this.searchValue = '';
			if(query == ''){
				this.auditeeOptions = []
			}else if(query.length > 0){
				this.auditeeOptions = this.optionsUserArray.filter((obj:any)=>{return obj.label.toLowerCase().includes(query.toLowerCase())})
			}
		},
        checkAfterRequired(action:any){
            this.validationErrors = this.sortedData.map((row:any) => {
                    return {
                        auditee: !row.auditee,
                        auditor: !row.auditor,
                        sameAuditorAuditee: row.auditor.value === row.auditee.value
                    };
                });

                if(action == 'save'){
                    const hasErrors = this.validationErrors.some((errors:any) => 
                        errors.auditee || errors.auditor || errors.sameAuditorAuditee
                    );

                    if (hasErrors) {
                        // Prevent saving and show validation messages
                        console.log('Prevent saving and show validation messages')
                    }else{
                        emitter.emit('auditValidationDone',this.action)
                        console.log('auditValidationDoneauditValidationDoneauditValidationDone')
                    }
                }
        },
        initialize() {
            this.tableRow = this.tableRowData;
            this.searchTableRow = this.tableRowData;

            this.tableRowData[0] == undefined ? '' : (this.entityId = this.tableRowData[0].entityId);
            console.log('this.entityId', this.entityId);
        },
        paginationUpdations(currentPage: any, pageSize: any) {
            console.log('Pagination processing... ', currentPage, pageSize);
            this.currentPage = currentPage;
            this.pageSize = pageSize;
        },
        sort(tableHead: any) {
            this.className = tableHead;
            if (tableHead === this.currentSort) {
                this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
            }
            this.currentSort = tableHead;
            this.classSort = this.currentSortDir === 'asc' ? 'sort_asc' : 'sort_desc';
        },
        toolTip(column: any, e: any, index: any, row: any, status: any) {
            this.tipText = row[column];
            this.tipColumn = column;
            this.tipIndex = index;

            switch (status) {
                case 'text':
                    this.handleTextTooltip(e);
                    break;
                case 'circleButton':
                    if (e.offsetX < e.target.offsetWidth && e.offsetY < e.target.offsetHeight) {
                        this.pop = true;
                        this.styling.left = e.offsetX + e.target.offsetLeft + 13 + 'px';
                        this.styling.bottom = -18 + 'px';
                    }
            }
        },
        handleTextTooltip(e: any) {
            const container = e.currentTarget.closest('.table-ellipsis');
            if (container && this.isTextOverflowed(container)) {
                this.pop = true;
                this.styling.left = e.offsetX + 38 + 'px';
                this.styling.bottom = -75 + 'px';
            } else {
                this.pop = false;
            }
        },
        isTextOverflowed(element: any) {
            return element.scrollHeight > element.clientHeight;
        },
        getTeamName(deptId: any) {
            let user = this.departments.find((obj: any) => {
                return obj._id == deptId;
            });
            if (user == undefined) {
                let empty = '';
                return empty;
            } else {
                return user.displayName;
            }
        },
         getLocationName(locationId: any) {
            let user = this.locations.find((obj: any) => {
                return obj.value == locationId;
            });
            if (user == undefined) {
                let empty = 'N/A';
                return empty;
            } else {
                return user.label;
            }
        },
        doFalse() {
            this.pop = false;
        },
        async getAllUsers() {
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/users/getAll`).then((res: any) => {
                this.users = res.data;
            });
            for (var i = 0; i < this.users.length; i++) {
                this.optionsUserArray.push({ label: this.users[i].name, value: this.users[i]._id });
            }
        },
    }
});
</script>
