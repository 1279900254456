<template>
	<div class="pt-3 px-5 pb-2">
		<div class="items-center justify-between flex ResponsiveStartAudit">
			<div class="title flex items-center">
				<h1>My Tasks</h1>
			</div>
            <div>
                <button :class="tableRow.length == 0 ? 'btndisabled pointer-events-none' : 'filterButton'" class="flex" @click="filterOpen" >
                    Filters
                    <img src="@/assets/filter.svg" class="h-3 ml-2.5" />
                </button>
			</div>
		</div>
		<div class="mt-5 ResponsiveStartAudit">
			<p class="headerText">
				This course helps you to Understand current data protection legislation and underlying principles. It also helps you to Understand what Privacy Awareness is, what PII is, PII Processing, Individual Rights, Cross Border Transfers, PII Breaches, Privacy By Design. This course helps you
				to understand what Information Security is, awareness on information security, different kinds of security breaches happening in daily life, physical security, email security, Knowing your responsibilities when dealing with Information Security.
			</p>
		</div>
        <div>
			<tableData :tableRowData="tableRow" :headersData="mainHeaders" :loadData="dataLoading"></tableData>
            <div v-if="toolTipActive">
				<tool-tip :tipText="toolTipText"></tool-tip>
			</div>
		</div>

	</div>
</template>
<style scoped>
</style>
<script lang="ts">
import { defineComponent } from 'vue';
import _ from 'lodash';
import { required } from '@vuelidate/validators';
import { mapGetters } from 'vuex';
import { emitter, toast } from '@/main';
import tableData from '@/components/tableData.vue';
import toolTip from '@/components/toolTip.vue'

export default defineComponent({
	data(): any {
		return {
			showFilters: false,
            visibility: false,
            allFrameWorkControls:[],
            filters: {},
            tableRow:[],
            mainHeaders:[],
			dummyList:[],
            teamObject:[],
            locationObject:[],
            auditStatusCodes: [
				{
					id: 10401,
					value: 'Not Started'
				},
				{
					id: 10414,
					value: 'Pending Evidence'
				},
				{
					id: 10415,
					value: 'InReview'
				},
                {
					id: 10416,
					value: 'Evidence Uploaded'
				},
                {
					id: 10403,
					value: 'Completed'
				},
                {
					id: 10417,
					value: 'Not Applicable'
				},

			],
            routesArray: [],
            myTasksArray:[],
            teamsData:[],
            toolTipActive:false,
			toolTipText:'',
            dataLoading:true
		};
	},
	components: {
        tableData,
        toolTip
	},
	computed: {
		...mapGetters({ userInfo: 'userInfo' }),
	},
    async mounted(){
        emitter.on('visible', (isBoolean: any) => {
			this.visibility = isBoolean;
			this.visibility == false ? this.filters = {}:'';
		});

        emitter.off('clickedOnTableRow')
		emitter.on('clickedOnTableRow',async (actionObj:any)=>{
            this.goToControlTaskEdit(actionObj.auditId,actionObj._id)
		})

        emitter.off('activiteToolTip');
        emitter.on('activiteToolTip', (data: any) => {                        
            this.toolTipActive = true;
            this.toolTipText = data.tipText
        });
 
        emitter.off('deActiviteToolTip');
        emitter.on('deActiviteToolTip', (data: any) => {
            this.toolTipActive = false;
            this.toolTipText = ''
        });
    },
	methods: {
        filterOpen() {
			this.showFilters = !this.showFilters;
			if (this.showFilters == true) {
                this.dummyList = this.tableRow;
            } else {
                this.tableRow = this.dummyList;
            }
			emitter.emit('visible', this.showFilters);
		},
        goToControlTaskEdit(auditId:any,taskId:any){
            this.$router.push({ name: 'controlEdit', params: { auditId: auditId,taskId:taskId }, query:{myTasks:true} });
        },
        async getAllTeams() {
            this.teamObject = []
            let users:any
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/departments/get`).then((res: any) => {
                users = res.data;
                this.teamsData = res.data
            });
            for (var i = 0; i < users.length; i++) {
                this.teamObject.push({ label: users[i].teamName, value: users[i]._id });
            }
        },
		 async getallEntities() {
            this.locationObject = []
			let result:any
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/businessEntities/getall`).then((res: any) => {
                result = res.data;
            });
            for (var i = 0; i < result.length; i++) {
                this.locationObject.push({ label: result[i].entityName, value: result[i]._id });
            }
            console.log("locationObject",this.locationObject)
        },
         getLocationName(locationId: any) {
            let user = this.locationObject.find((obj: any) => {
                return obj.value == locationId;
            });
            if (user == undefined) {
                let empty = 'N/A';
                return empty;
            } else {
                return user.label;
            }
        },
        async getTasksForPerson(){
            let auditorPayload:any = {
                "type":1,
                "auditor": this.userInfo._id
            }
            this.myTasksArray = [];
            try{
                 await this.$http.post(`${process.env.VUE_APP_AUDIT_API_URL}/api/org/tasks/getall/byroles`,auditorPayload).then((result: any) => {
                   this.myTasksArray = result.data;
                });
            }catch{
                toast.error(`error`, {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            }
        },

        async getDetailsForTable(){

            this.tableRow = []
            this.myTasksArray.map((taskObj:any)=>{

                let presentLocation:any
                
                let presentteamObject:any = this.teamsData.find((obj: any) => {
                    return obj._id == taskObj.department;
                });

                if (presentteamObject != undefined && presentteamObject.entityType == 20301){
                    presentLocation = 'Global';
                }else if(presentteamObject != undefined && presentteamObject.entityType == 20303){
                    // presentLocation = this.getLocationName(presentteamObject.entityId)
                    presentLocation = presentteamObject.entityName
                }
                console.log("taskObj",taskObj)

                let sendObject:any = { 
                    "_id": taskObj._id,
                    "auditId":taskObj.auditId != undefined ? taskObj.auditId : 'N/A', 
                    "taskId": taskObj.taskId  != undefined ? taskObj.taskId : 'N/A',
                    "CCID": taskObj.clientUniqueId != undefined ? taskObj.clientUniqueId : 'N/A',
                    "controlName": taskObj.controlTitle != undefined ? taskObj.controlTitle : 'N/A', 
                    "teamName": presentteamObject.displayName  != undefined ? presentteamObject.displayName : 'N/A',
                    "entityName": presentLocation  != undefined ? presentLocation : 'N/A',
                    "taskStatus": taskObj.statusCode != undefined ? taskObj.statusCode : 'N/A',
                };

                this.tableRow.push(sendObject)
            })
        }
	},

	async created() {
        this.dataLoading = true;

        let headersData = ['taskId','CCID','controlName','teamName','entityName','taskStatus']

        this.mainHeaders = headersData;

        await this.getAllTeams();
        await this.getallEntities();
        await this.getTasksForPerson()

        await this.getDetailsForTable();

        this.dataLoading = false;
    },
});
</script>
