<template>
    <!-- <div v-if="dotsLoading" class="mt-2">
		<div class="flex items-center justify-center w-full h-16 skeleton mt-4 tableCornerRadius bg-white">
			<dots-loader/>
		</div>
    </div> -->
	<div class="mb-5 mx-5 mt-3 ResponsiveControlePage">
		<div class="mb-2">
			<breadCrumbs :routesArray="routesArray"></breadCrumbs>
		</div>
		<div class="row w-full h-full flex mt-2 justify-between">
			<div class="w-2/5">
				<h1 class="title">{{ presentFindingTaskObj.taskId }}</h1>
			</div>
			<!-- for total task approval -->
			<div class="flex flex-row" v-if="presentFindingTaskObj.auditor == userInfo._id && (presentFindingTaskObj.statusCode == 10419 || presentFindingTaskObj.statusCode == 10415 )">
				<button class="btnprimary items-center btnPrimaryPadding mr-2" @click="approveOrRejectForActionTasktoComplete(10403,7)">Approve</button>
				<button class="btnprimary items-center btnPrimaryPadding" @click="clickToRejectByJustification()">Reject</button>
			</div>

			<!-- for normal approval -->
			<div class="flex flex-row" v-if=" presentFindingTaskObj.approvals!= undefined && presentFindingTaskObj.approvals[presentFindingTaskObj.approvals.length - 1].statusCode== 10401 && presentFindingTaskObj.approvals[presentFindingTaskObj.approvals.length - 1].approver == userInfo._id">
                <button class="btnprimary items-center btnPrimaryPadding mr-2" @click="ApprovalforInprogress(10403)">Approve</button>
                <button class="btnprimary items-center btnPrimaryPadding" @click="onClickToRejectByJustificationAtInReview()">Reject</button>
            </div>
		</div>
		
		<div class="w-full minheight">
			<div class="w-full">
				<div class="text-sm">
					<div class="mainContainer tabStructure flex flex-row w-full bg-white border rounded-sm mt-5 px-5 pt-5 pb-3 border-lightgrey">
						<div class="w-75 heightFixing pr-5">
							<div class="tabStructureDiv">
								<div class="flex flex-row borderBottom justify-between cursor-pointer tab-container">
									<div class="pb-1" :class="selectedTabIndex == tab.id ? 'adjust header font-semibold':'text-sm text-disabledgrey'" v-for="(tab, tabIndex) in tabHead" :key="tabIndex" @click="toggleTabs(tab)">
										<div>
											<div class="cursor-pointer">{{ tab.displayName }}</div>
											<div :class="{ progress: selectedTabIndex == tab.id }" class="h-1 p-0.75"></div>
										</div>
									</div>
								</div>
								<div class="progressbarB rounded h-1"></div>
							</div>
							<div class="h-full">
								<div class="overflow-y-scroll descriptionToggle" v-if="selectedTabObj.id == 1">
									
									<div>
										<div class="border-b border-lightgrey">
											<p class="mt-1 twopxtop mb-2 header">Action</p>
										</div>
										<p class="mt-2 headerText">{{ presentFindingTaskObj.action }}</p>
									</div>
								</div>
								<div class="overflow-y-scroll descriptionToggle" v-if="selectedTabObj.id == 2">
									<div>
										<div class="border-b border-lightgrey flex justify-between pb-2">
											<div class="flex items-center mt-0.5 marginTopForDoc marginBottomForDoc justify-center">
												<div>
													<p class="header">Documents which are related to the controls</p>
												</div>
											</div>
										</div>
										<!-- <p class="mt-1.5">{{ descriptionObject._id }}</p> -->
									</div>
								</div>
								<div class="overflow-y-scroll descriptionToggle" v-if="selectedTabObj.id == 3">
									<div>
										<!-- <div class="border-b border-lightgrey">
											<div class="flex justify-between mb-1.5">
												<div class="header flex justify-center items-center">Files </div>
												<div class="buttonposition flex justify-center items-center">
													 <input  type="file" id="upload" ref="element"/>
													<button class="btnprimary w-24 flex justify-center taskEditButtons">Attach</button> 
													<button @click="openFilePicker" for="upload" class="btnprimary w-24 flex justify-center taskEditButtons">Attach</button>
    												<input type="file" ref="fileInput" id="upload" @change.stop="handleFileChange" style="display: none" />
												</div>
											</div>
										</div> -->
										<div class="border-b border-lightgrey flex justify-between">
											<div><p class="mt-1 header">Files </p></div>
											<div class="mb-2">
												<div class="buttonposition flex justify-center items-center">
                                                    <button @click="openFilePopup" for="upload" :class="TaskDetailsDropDown.taskStatus ==10419 || TaskDetailsDropDown.taskStatus == 10403 || TaskDetailsDropDown.taskStatus == 10417 || presentAuditObject.statusCode == 10403|| presentAuditObject.statusCode == 10418 || presentFindingTaskObj.auditor == userInfo._id? 'btndisabled pointer-events-none paddingForAttachButton' : 'btnprimary flex justify-center taskEditButtons'" class="w-24 flex justify-center ">Attach</button>
												</div>
											</div>
										</div>
										
										<div v-if="Object.keys(presentFindingTaskObj).includes('files')" class="flex mt-5 gap-4 flex-wrap">
											<div v-for="doc in presentFindingTaskObj.files" :key="doc">
												<div class="pdf w-full rounded-sm">
													<a :href="doc.url" class="flex w-full flex-row items-center">
														<img src="@/assets/pdfs.svg" alt="" />
														<p v-if="Object.keys(doc).includes('filename')" class="text-xs ml-2">{{doc.filename}}</p>
														<p v-else class="text-xs ml-1">{{ doc.name }}</p>
													</a>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="overflow-y-scroll descriptionToggle" v-if="selectedTabObj.id == 4">
									<div>
										<div class="border-b border-lightgrey flex justify-between pb-2">
											<div class="flex items-center justify-center">
												<div>
													<p class="header">Comments</p>
												</div>
											</div>
											<div>
												<div class="buttonposition flex justify-center items-center">
													<div class="buttonposition flex justify-center items-center">
														<button class="btnprimary w-24 flex taskEditButtons" v-if="oldComments" @click="sortComments()">
															<img src="@/assets/sortBy.svg" class="h-3 mr-2.5" />
															Oldest
														</button>
														<button class="btnprimary w-24 flex taskEditButtons" v-if="!oldComments" @click="sortComments()">
															<img src="@/assets/sortBy.svg" class="h-3 mr-2.5 rotate-180" />
															Newest
														</button>
													</div>
												</div>
											</div>
										</div>
										<div>
											<div class="comment-box w-full mt-5">
												<!-- <div class="my-3 headerText"><textarea placeholder="Add a Comment" class="w-full border border-lightgrey rounded-sm p-2" rows="4" :disabled="TaskDetailsDropDown.taskStatus == 10403 || TaskDetailsDropDown.taskStatus == 10417 || presentAuditObject.statusCode == 10403|| presentAuditObject.statusCode == 10418" v-model="comment" @input="handleInput"></textarea></div>
												<div class="flex justify-end">
													<button @click="addComment" class="mr-2" :class="{ btnprimary: !btnDisabled, btndisabled: btnDisabled }" :disabled="btnDisabled">Add</button>
													<button @click="clearComment" :class="{ btnprimary: !btnDisabled, btndisabled: btnDisabled }" :disabled="btnDisabled">Cancel</button>
												</div> -->
												<commentsEditor/>
											</div>
											<div v-if="Object.keys(presentFindingTaskObj).includes('comments')" class="comments">
												<div v-for="(obj, index) in presentFindingTaskObj.comments" class="border border-lightgrey mt-2 rounded-sm flex flex-row p-3" :key="index">
													<div class="w-20">
														<div class="header">{{ getPersonName(obj.createdBy) }}</div>
														<div class="fontSize10PX">{{ obj.createdAt }}</div>
													</div>
													<div class="w-80 headerText">
													   <safeHtml :content="obj.comment"/>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="overflow-y-scroll descriptionToggle" v-if="selectedTabObj.id == 5">
									<div>
										<div class="border-b border-lightgrey flex justify-between pb-2">
											<div class="flex items-center justify-center">
												<div>
													<p class="header">Approvals</p>
												</div>
											</div>
											<div>
												<div class="buttonposition flex justify-center items-center">
													<button @click="openApprovalpopup()" :class="TaskDetailsDropDown.taskStatus ==10419 || approvalObj.statusCode == 10401 || presentFindingTaskObj.statusCode == 10403 ||  presentFindingTaskObj.auditor == userInfo._id || presentFindingTaskObj.auditee == userInfo._id ||  (newTeamAssignee.value != userInfo._id  && presentFindingTaskObj.auditee != userInfo._id) ?'btndisabled pointer-events-none paddingForAttachButton' : 'btnprimary flex justify-center taskEditButtons'" class="w-32 flex justify-center ">Send Approval</button>
												</div>
											</div>
										</div>
										<div>
											<div v-for="(approval, number) in presentFindingTaskObj.approvals" :key="number" class="bg-white flex flex-row p-4 mb-0.5 border-lightgrey border-b w-full">
												<div v-if="approval.createdBy != undefined" class="initials">{{extractInitials(getPersonName(approval.createdBy))}}</div>
												<div v-if="approval.createdBy != undefined" class="font-semibold mt-1 text-xs">{{ getPersonName(approval.createdBy) }}</div>
												<div v-if="approval.updatedBy != undefined" class="initials">{{extractInitials(getPersonName(approval.updatedBy))}}</div>
												<div v-if="approval.updatedBy != undefined" class="font-semibold mt-1 text-xs">{{ getPersonName(approval.updatedBy) }}</div>
												<div v-if="approval.statusCode == 10401" class="mx-2 mt-1 text-xs"> {{' Requested an approval '}} </div>
												<div v-if="approval.statusCode == 10410" class="mx-2 mt-1 text-xs"> {{' Rejected an approval '}} </div>
												<div v-else-if="approval.statusCode == 10403" class="mx-2 mt-1 text-xs"> {{' Approved an approval '}} </div>
												<div  v-if="approval.createdAt != undefined" class="font-semibold mt-1 text-xs">{{approval.createdAt}}</div>
												<div  v-if="approval.updatedAt != undefined" class="font-semibold mt-1 text-xs">{{approval.updatedAt}}</div>
											</div>
										</div>
									</div>
								</div>
								<div class="overflow-y-scroll descriptionToggle" v-if="selectedTabObj.id == 6">
									<div>
										<div class="border-b border-lightgrey flex justify-between pb-2">
											<div class="flex items-center justify-center">
												<div>
													<p class="header">History</p>
												</div>
											</div>
											<div>
												<div class="buttonposition flex justify-center items-center">
													<div class="buttonposition flex justify-center items-center">
														<button class="btnprimary w-24 flex taskEditButtons" v-if="newHistory" @click="sortWorkLog()">
															<img src="@/assets/sortBy.svg" class="h-3 mr-2.5" />
															Oldest
														</button>
														<button class="btnprimary w-24 flex taskEditButtons" v-if="!newHistory" @click="sortWorkLog()">
															<img src="@/assets/sortBy.svg" class="h-3 mr-2.5 rotate-180" />
															Newest
														</button>
													</div>
												</div>
											</div>
										</div>
											<div>
												
												<div v-for="work in workLogObject.taskHistory" :key="work" class="bg-white p-4 mb-0.5 border-lightgrey border-b w-full">
													<div class="flex">
														<div v-if="checkKey(work,'createdBy')" class="initials">
															{{ extractInitials(getPersonName(work.createdBy)) }}
														</div>
														<div v-if="Object.keys(work).includes('updatedBy')" class="initials">
															{{ extractInitials(getPersonName(work.updatedBy)) }}
														</div>
														<div class="logContainer w-93">
															<div class="flex mt-1">
																<p v-if="Object.keys(work).includes('createdBy')" class="font-semibold mt-1 text-xs">
																	{{ getPersonName(work.createdBy) }}
																</p>
																<p v-else-if="Object.keys(work).includes('updatedBy')" class="font-semibold mt-1 text-xs">
																	{{ getPersonName(work.updatedBy) }}
																</p>

																<p v-if="work.actionEntity == 10301" class="mx-2 mt-1 text-xs">{{ 'Created the Task on' }}</p>
																<p v-else-if="work.statusCode != undefined && work.statusCode == 10418" class="mx-2 mt-1 text-xs">{{ 'Terminated the Task on' }}</p>
																<p v-else-if="work.actionEntity == 10302" class="mx-2 mt-1 text-xs">{{ 'Updated the Task on' }}</p>

																<p v-if="Object.keys(work).includes('createdAt')" class="font-semibold mt-1 text-xs">{{ formatTime(work.createdAt) }}</p>
																<p v-if="Object.keys(work).includes('updatedAt')" class="font-semibold mt-1 text-xs">{{ formatTime(work.updatedAt) }}</p>
															</div>
															
															<div class="mt-3" v-if="Object.keys(work).includes('additionalInfo')">
																
																	<div v-if="work.additionalInfo">
																		<div v-if="checkKey(work.additionalInfo,'comment')" class="flex">
																			<div class="font-semibold text-xs widthForCommentBox">Comment :</div>
																			<div class="text-xs w-full ">
																				<safeHtml :content="work.additionalInfo.comment"/>
																			</div>
																		</div>
																	<div class="flex" v-if="Object.keys(work.additionalInfo).includes('evidence')">
																		<p class="font-semibold text-xs">{{'Uploaded the Evidence -'}}</p>
																		<p class="mx-2 text-xs">{{ work.additionalInfo.evidence }}</p>
																	</div>
																	<div class="flex" v-if="Object.keys(work.additionalInfo).includes('assignee')">
																		<p class="font-semibold text-xs">{{'Assigned the Task To -'}}</p>
																		<p class="mx-2 text-xs">{{ getPersonName(work.additionalInfo.assignee) }}</p>
																	</div>
																	<div class="flex" v-if="Object.keys(work.additionalInfo).includes('approver')">
																		<div v-if="work.additionalInfo.action == 10301">
																			<p class="font-semibold text-xs">{{'Sent Approval for -'}}</p>
																		</div>
																		<div v-if="work.additionalInfo.action == 10302 && work.additionalInfo.statusCode == 10403">
																			<p class="font-semibold text-xs">{{'Approved Approval -'}}</p>
																		</div>
																		<div class="flex" v-if="work.additionalInfo.action == 10302 && work.additionalInfo.statusCode == 10410">
																			<p class="font-semibold text-xs">{{'Rejected Approval -'}}</p>
																		</div>
																		<p class="mx-2 text-xs text-justify">{{ getPersonName(work.additionalInfo.approver) }}</p>
																   </div> 
																	<div class="flex items-center" v-if="Object.keys(work.additionalInfo).includes('previousStatus')">
                                                                    <div>
                                                                        <p class="font-semibold text-xs">{{'Updated the status :'}}</p>
                                                                    </div>
																	<!-- <div v-if="checkKey(work.additionalInfo,'action')" class="flex">
																		<div class="font-semibold text-xs w-12">Approval :</div>
																		<div class="text-xs w-88 ">{{ work.additionalInfo.checkapproval }}</div>
																		<div v-for="(approval, number) in presentFindingTaskObj.approvals" :key="number" class="bg-white flex flex-row p-4 mb-0.5 border-lightgrey border-b w-full">
																			<div class="initials">{{extractInitials(getPersonName(work.additionalInfo.createdBy))}}</div>
																			<div class="font-semibold mt-1 text-xs">{{ getPersonName(work.additionalInfo.createdBy) }}</div>
																			<div v-if="work.additionalInfo.statusCode == 10401" class="mx-2 mt-1 text-xs"> {{' Requested an approval '}} </div>
																			<div v-else-if="work.additionalInfo.statusCode == 10410" class="mx-2 mt-1 text-xs"> {{' Rejected an approval '}} </div>
																			<div v-else-if="work.additionalInfo.statusCode == 10403" class="mx-2 mt-1 text-xs"> {{' Approved an approval '}} </div>
																			<div class="font-semibold mt-1 text-xs">{{work.additionalInfo.createdAt}}</div>
																		</div>
																	</div> -->
                                                                    <div class="flex ml-4">
                                                                        <div class="flex items-center justify-center rounded h-5 labelWidth" :class="colorFindingBg(work.additionalInfo.previousStatus)">
                                                                            <div :class="colorFindingText(work.additionalInfo.previousStatus)">
                                                                                {{ getStatusLabel(work.additionalInfo.previousStatus) }}
                                                                            </div>
                                                                        </div>
                                                                        <div class="mx-4 flex items-center"><img src="@/assets/arrowRight.svg"/></div>
																			<div class="flex items-center justify-center rounded h-5 labelWidth" :class="colorFindingBg(work.additionalInfo.updatedStatus)">
																				<div :class="colorFindingText(work.additionalInfo.updatedStatus)">
																					{{ getStatusLabel(work.additionalInfo.updatedStatus) }}
																				</div>
																			</div>
																		</div>
																	</div>
																	</div>

																
																<!-- <div v-if="Object.keys(work.additionalInfo).includes('evidence')" class="flex">
																	<div class="font-semibold text-xs w-12">Comment :</div>
																	<div class="text-xs w-88 ">{{ work.addtionalInfo.comment }}</div>
																</div>
																<div class="flex" v-if="Object.keys(work.additionalInfo).includes('evidence')">
																	<p class="font-semibold text-xs">{{'Uploaded the Evidence -'}}</p>
																	<p class="mx-2 text-xs">{{ work.additionalInfo.evidence }}</p>
																</div>
																
																<div class="flex" v-if="Object.keys(work.addtionalInfo).includes('assignee')">
																	<p class="font-semibold text-xs">{{'Assigned the Task To -'}}</p>
																	<p class="mx-2 text-xs">{{ getPersonName(work.addtionalInfo.assignee) }}</p>
																</div> -->
																
															</div>
														</div>
													</div>
												</div>
											</div>

										<!-- </div> -->
									</div>
								</div>
							</div>
						</div>
						<div class="w-25 border taskDetailsRightBar rounded-sm border-lightgrey">
							<div class="m-3">
								<div class="pb-2 border-b border-lightgrey">
									<h1>Task Details</h1>
								</div>
								<div class="pt-1.5 overflow-y-scroll thirtyWidthToggle">
									<div class="flex flex-col">
										<label class="controllabel">Task Status</label> 
										<div>
											 <div v-if="TaskDetailsDropDown.taskStatus == 10418" class="bg-error textColorWhite inputboxstyling1 cursor-pointer mt-2 ">
												<div class="bg-error textColorWhite">{{'Terminated'}}</div>
											</div>
											<input disabled v-else-if="TaskDetailsDropDown.taskStatus == 10403" type="text" placeholder="Status" class="completedBox inputboxstyling mt-2 " value="Completed" />

											<select
												class="mt-2  custom-select cursor-pointer w-full px-2.5 py-1 text-sm font-light text-gray rounded-sm border-solid border border-lightgrey"
												:class="changeBackground()"
												v-if="(checkAuditor(presentFindingTaskObj.auditor)) && (TaskDetailsDropDown.taskStatus == 10419 || TaskDetailsDropDown.taskStatus == 10415)"		
												v-model="TaskDetailsDropDown.taskStatus"
												@change="updateTaskStatus(TaskDetailsDropDown.taskStatus)"
											>
												<option value="" selected disabled hidden>Please select one</option>
												<option v-for="i in auditorTaskData" :disabled="i.id==10403" :class="i.id == 10403 ? 'taskStatusOptionsForNotStarted' : 'taskStatusOptions'" :value="i.id" :key="i">
													{{ i.value }}
												</option>
											</select>
											<select
												class=" mt-2  custom-select cursor-pointer w-full px-2.5 py-1 text-sm font-light text-gray rounded-sm border-solid border border-lightgrey"
												:class="changeBackground()"
												v-else-if="!(checkAuditor(presentFindingTaskObj.auditor)) && (TaskDetailsDropDown.taskStatus == 10401 || TaskDetailsDropDown.taskStatus == 10402 || TaskDetailsDropDown.taskStatus == 10408 || TaskDetailsDropDown.taskStatus == 10419)"
												:disabled="TaskDetailsDropDown.taskStatus == 10419 ||  TaskDetailsDropDown.taskStatus == 10415 ||  TaskDetailsDropDown.taskStatus == 10403"
												v-model="TaskDetailsDropDown.taskStatus"
												@change="updateTaskStatus(TaskDetailsDropDown.taskStatus)"
											>
												<option value="" selected disabled hidden>Please select one</option>
												<option v-for="i in taskStatusData" :value="i.id" :key="i">
													{{ i.value }}
												</option>
											</select>
										</div>
										<div>
											<div v-if="!(checkAuditor(presentFindingTaskObj.auditor)) && (presentFindingTaskObj.statusCode == 10415 )" :class="changeBackground()" class="inputboxstyling1 disabled cursor-pointer mt-2 " >
												<div :class="changeBackground()" @change="updateTaskStatus(TaskDetailsDropDown.taskStatus)">
													{{ 
														auditorTaskData.find((data)=>{
															return data.id == presentFindingTaskObj.statusCode
														}).value
													}}
												</div>
											</div>
											<div v-if="(checkAuditor(presentFindingTaskObj.auditor)) && (TaskDetailsDropDown.taskStatus == 10401 || TaskDetailsDropDown.taskStatus == 10402 || TaskDetailsDropDown.taskStatus == 10408)" :class="changeBackground()" class="inputboxstyling1 disabled cursor-pointer mt-2" >
												<div :class="changeBackground()" @change="updateTaskStatus(TaskDetailsDropDown.taskStatus)">
													{{ 
														taskStatusData.find((data)=>{
															return data.id == presentFindingTaskObj.statusCode
														}).value
													}}
												</div>
											</div>
										</div>
									</div>
		
									<div class="mt-3">
										<div>
											<label class="controllabel">Assigned To</label>	
											<div class="mt-2" v-if="TaskDetailsDropDown.taskStatus ==10419 || TaskDetailsDropDown.taskStatus == 10403 || TaskDetailsDropDown.taskStatus == 10417 || presentFindingTaskObj.statusCode == 10403|| presentFindingTaskObj.statusCode == 10418 || presentFindingTaskObj.auditor == userInfo._id || (newTeamAssignee.value != userInfo._id  && presentFindingTaskObj.auditee != userInfo._id)">{{presentFindingTaskObj.assignee != undefined ? presentFindingTaskObj.assignee.label : 'N/A'}}</div>
											<div v-else>
												<multiselect
													v-model="presentFindingTaskObj.assignee"
													:options="userOptions"
													:showNoOptions="false"
													@search-change="asyncFind"
													:limit-text="limitText"
													:multiple="false"
													:close-on-select="true"
													:clear-on-select="true"
													label="label"
													open-direction="bottom"
													:hide-selected="true"
													track-by="value"   
													@select="updateAssignedMember(presentFindingTaskObj.assignee)" 
													:searchable="true"
													:hideArrow="false"
													placeholder="Assigned-To"
													deselectLabel=""
													selectLabel=""
													class="custom-multiselect userSelect mt-2"
													:class="{
														'has-options': userOptions && userOptions.length > 0,
														'no-options': !userOptions.length &&  searchValue !== '',
													}"
												>
													<template v-slot:noResult>
														<span class="text-xs">No Members Found</span>
													</template>
												</multiselect>
											</div>
										</div>
									</div>

									<div class="mt-3">
                                        <label class="controllabel">Auditor</label><br>
										<div class="my-1 mt-2 text-sm" v-if="presentFindingTaskObj.auditor != undefined || presentFindingTaskObj.auditor != null">{{ getPerson(presentFindingTaskObj.auditor) }}</div>
										<p class="mt-2 text-sm" v-else>N/A</p>
                                    </div>
                                    <div class="mt-3">
                                        <label class="controllabel">Auditee</label><br>
										<div class="my-1 text-sm" v-if="presentFindingTaskObj.auditee != undefined || presentFindingTaskObj.auditee != null">{{ getPersonName(presentFindingTaskObj.auditee) }}</div>
										<p class="mt-2 text-sm" v-else>N/A</p>
                                    </div>
                                    <div class="mt-3">
                                        <label class="controllabel">Team</label><br>
										<div class="my-1 text-sm" v-if="presentFindingTaskObj.department != undefined || presentFindingTaskObj.department != null">{{ getTeamName(presentFindingTaskObj.department) }}</div>
										<p class="mt-2 text-sm" v-else>N/A</p>
                                    </div>
									<div class="mt-3">
                                        <label class="controllabel">Entity</label><br>
										<div class="my-1 text-sm"> {{ getLocationName(presentFindingTaskObj.department) }}</div>
                                        <!-- <div class="mt-2 text-sm" v-else>{{ 'N/A' }}</div> -->
                                    </div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<popup v-if="importpopUp" :popUp="importpopUp">
			<div class="overflow-x-hidden overflow-y-auto fixed inset-0 z-index justify-end items-start flex">
			<div class="relative w-full max-w-xl">
			<!--content-->
			<div class="border-0 rounded relative flex flex-col w-full bg-white h-screen">
				<!--header-->
				<div class="flex items-center justify-between popupheadingcontainer rounded">
				<h1>Import files</h1>
				<button class="p-1 ml-auto float-right text-3xl leading-none font-semibold outline-none focus:outline-none" @click="onCancelIcon()">
					<img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
				</button>
				</div>
				<div class="border-b border-solid border-lightgrey ml-4 mr-4"></div>
				<div class="impactcontainer popupbodycontainer">
				<div class="formcontainer flex flex-col">
					<div class="row flex flex-col mt-2">
					<div>
						<label class="controllabel">File Name </label>
						<input class="inputboxstyling headerText mt-2" type="text" v-model.trim="inputFileName" placeholder="Filename"  :class="{'is-invalid': v$.inputFileName.$error }"/>
						<div v-if="v$.inputFileName.$error " class="text-red-500">
							<div class="error-text" v-if="v$.inputFileName.required.$invalid ">Required</div>
						</div>
					</div>
					<div class="pt-3">
						<label class="controllabel">Attachment</label>
						<div class=" mt-2 w-auto bg-white rounded-sm border-solid border border-lightgrey relative overflow-hidden " :class="{'is-invalid':v$.fileToUpload.$error}">
							<!-- <label htmlFor="upload" class="controllabel">Upload File</label> -->
							<input type="file" ref="fileInput" class="hidden" id="upload" @change="handleFileUpload"/>
							<label for="upload" class="float-right cursor-pointer text-white flex justify-center items-center w-9 h-6 bg-primary"><img src="@/assets/Browse.svg" /></label>
							<div v-if="fileName" class="text-xs p-1 headerText">{{ fileName }}</div>
						</div>
					</div>
					
					<div v-if="v$.fileToUpload.$error" class="text-red-500">
						<div class="error-text" v-if="v$.fileToUpload.$invalid">File upload is required</div>
					</div>
					</div>
				</div>
				</div>
				<!--footer-->
				<div class="buttonposition flex justify-center items-center">
					<button class="btnprimary" @click="handleFileChange" :class="(inputFileName.length == 0) ? 'btndisabled pointer-events-none ' : 'btnprimary'"  :disabled="clickOnButton">Save</button>
				</div>
			</div>
			</div>
			</div>
		</popup>
		<popup v-if="showApprovalPopup" :popUp="showApprovalPopup">
			<template v-slot:header>
				<div class="flex items-center justify-between popupheadingcontainer rounded">
					<h1>Approval</h1>

					<button class="float-right text-3xl" @click="onCrossbtn()">
						<img src="@/assets/cancel.svg" class="cursor-pointer" />
					</button>
				</div>
			</template>

			<div class="popup-business">
				<div>
					<div>
						<div class="statuscontainer px-5 pb-5">
							<div class="formcontainer flex flex-col">
								<div class="row flex flex-row mt-3">
									<div class=" w-1/2">
										<label class="controllabel">Name of the Authority</label>
										<multiselect
											v-model="approvalSent"
											:options="userOptions"
											:showNoOptions="false"
											@search-change="asyncFind"
											:limit-text="limitText"
											:multiple="false"
											label="label"
											open-direction="bottom"
											track-by="value"
											:searchable="true"
											:hideArrow="false"
											placeholder="Assigned-To"
											deselectLabel=""
											selectLabel=""
											class="custom-multiselect mt-2"
											:class="{
												'has-options': userOptions && userOptions.length > 0,
												'no-options': !userOptions.length &&  searchValue !== '',
											}"
										>
										<template v-slot:noResult>
											<span class="text-xs">No Members Found</span>
										</template>
										</multiselect>

										<!-- <select disabled class="inputboxstyling" :value="presentFindingTaskObj.auditee">
											<option v-for="option in orgUsersList" :value="option._id">{{option.name}}</option>
										</select> -->
										<!-- <div class="inputboxstyling" disabled>{{ getPersonName(presentFindingTaskObj.auditee) }}</div> -->
										 <!-- <input type="text" :value="getPerson(presentFindingTaskObj.auditee)" class="inputboxstyling"/> -->
									</div >
								</div>
								<div class="row flex flex-row mt-3">
									<div class="w-full">
										<label class="controllabel">Justification or Comments for Approving</label>
										<textarea
											class="inputboxstyling mt-2"
											placeholder="Comments"
											v-model.trim="taskObj.approvalComments"
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="buttonposition flex justify-center items-center mb-4 mx-0">
						<button class="btnprimary mr-2" :class="(taskObj.approvalComments=='')?'btndisabled pointer-events-none':'btnprimary'" @click="onCancelbtnAtApproval()">Cancel</button>
						<button class="btnprimary" :class="(taskObj.approvalComments=='')?'btndisabled pointer-events-none':'btnprimary'" :disabled="clickOnButtonForApproval" @click="clickOnSaveForApproval()">Save</button>
					</div>
				</div>
			</div>
		</popup>

		<popup v-if="showRejectPopupForNormalApproval" :popUp="showRejectPopupForNormalApproval">
				<template v-slot:header>
					<div
					class="flex items-center justify-between popupheadingcontainer rounded"
					>
					<h1>Justification to Reject an Approval</h1>

					<button class="float-right text-3xl" @click="onCrossBtnForNormalApproval()">
						<img src="@/assets/cancel.svg" class="cursor-pointer" />
					</button>
					</div>
				</template>

			<div class="popup-business">
				<div>
				<div>
					<div class="statuscontainer px-4 py-4">
					<div class="formcontainer flex flex-col w-full">
							<label class="controllabel"
							>Justification or Comments for Approving</label
							>
							<textarea
							class="inputboxstyling mt-2"
							placeholder="Comments"
							v-model.trim="normalJustification.justification"
							/>
					</div>
					</div>
				</div>
				<div
					class="buttonposition flex justify-center items-center mb-4 mx-0"
				>
					<button
					class="btnprimary mr-2"
					:class="
						normalJustification.justification == ''
						? 'btndisabled pointer-events-none'
						: 'btnprimary'
					"
					@click="onCancelbtnForNormalApproval()"
					>
					Cancel
					</button>
					<button
					class="btnprimary"
					:class="
						normalJustification.justification == ''
						? 'btndisabled pointer-events-none'
						: 'btnprimary'
					"
					@click="approveOrRejectForActionTasktoComplete(10401,7)"
					:disabled="clickOnButtonForApproveOrReject"
					>
					Save
					</button>
				</div>
				</div>
			</div>
		</popup>
		

		<popup v-if="showRejectPopupAtInReview" :popUp="showRejectPopupAtInReview">
		<template v-slot:header>
			<div
			class="flex items-center justify-between popupheadingcontainer rounded"
			>
			<h1>Justification to Reject an Approval</h1>

			<button class="float-right text-3xl" @click="onCrossBtnForFinalApproval()">
				<img src="@/assets/cancel.svg" class="cursor-pointer" />
			</button>
			</div>
		</template>

		<div class="popup-business">
			<div>
			<div>
				<div class="statuscontainer px-4 py-4">
				<div class="formcontainer flex flex-col w-full">
						<label class="controllabel"
						>Justification or Comments for Approving</label
						>
						<textarea
						class="inputboxstyling mt-2"
						placeholder="Comments"
						v-model.trim="finalJustification.justification"
						/>
				</div>
				</div>
			</div>
			<div
				class="buttonposition flex justify-center items-center mb-4 mx-0"
			>
				<button
				class="btnprimary mr-2"
				:class="
					finalJustification.justification == ''
					? 'btndisabled pointer-events-none'
					: 'btnprimary'
				"
				@click="onCancelbtnForFinalApproval()"
				>
				Cancel
				</button>
				<button
				class="btnprimary"
				:class="
					finalJustification.justification == ''
					? 'btndisabled pointer-events-none'
					: 'btnprimary'
				"
				@click="ApprovalforInprogress(10410)"
				:disabled="clickOnButtonForInProgress"
				>
				Save
				</button>
			</div>
			</div>
		</div>
		</popup>
	</div>
</template>
<style scoped>
.completedBox{
	background-color: #00B050 !important;
	color: white;
	pointer-events: none;
}
.taskStatusOptions{
  background-color: white; 
  color: rgba(77, 77, 77, var(--tw-text-opacity))
 }
 .taskStatusOptionsForNotStarted{
   background-color: #e9e9e9; 
   color: rgba(77, 77, 77, var(--tw-text-opacity))
 }
.widthForCommentBox{
	width : 75px;
}
.progressbarB {
	border: 2px solid #e9e9e9;
	background: #e9e9e9;
	position: relative;
	top: -8px;
	z-index: 1;
}
.numberBorder{
    border-top: 1px solid #e9e9e9;
    border-right: 1px solid #e9e9e9;
    border-bottom: 1px solid #e9e9e9;
}
.numberNavy{
    border-left: 1px solid #152A43;
    border-width: 2px;
}
.progress {
	background: #152a43;
	position: relative;
	border-radius: 4px;
	z-index: 2;
}
.primaryBackground{
	background-color: #152a433b
}
.descriptionToggle {
	height: 97%;
}
.tabStructure {
	height: calc(100vh - 165px);
}
.thirtyWidthToggle::-webkit-scrollbar {
	width: 0 !important;
	height: 0 !important;
	background: transparent;
}

.thirtyWidthToggle::-webkit-scrollbar-thumb {
	background: transparent;
}
.thirtyWidthToggle{  
     height:  calc(100vh - 250px);
}
.descriptionToggle::-webkit-scrollbar {
	width: 0 !important;
	height: 0 !important;
	background: transparent;
}

.descriptionToggle::-webkit-scrollbar-thumb {
	background: transparent;
}
.heightFixing {
	height:  calc(100vh - 220px);
	/* height : 64vh; */
}
option {
	background-color: #ffffff;
	color: #000000;
	border-color: pink transparent transparent transparent;
}
.textColorWhite {
	color: white;
}
.w-20{
	width : 20%;
}
.w-80{
	width : 80%;
}
.twopxtop{
   margin-top : 5px;
}
.selectCustom {
	/* background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.65166 4.18411C1.39599 4.28576 1.20193 4.46133 1.07564 4.69852C0.980151 4.88334 0.97399 5.35463 1.06332 5.54561C1.14957 5.72734 8.24662 12.8275 8.44068 12.926C8.56081 12.9876 8.62857 13 8.875 13C9.12143 13 9.18919 12.9876 9.30932 12.926C9.50338 12.8275 16.6004 5.72734 16.6867 5.54561C16.776 5.35463 16.7698 4.88334 16.6744 4.69852C16.5789 4.5137 16.3879 4.32272 16.2123 4.23339L16.0675 4.15946L8.9058 4.1533C3.46597 4.15022 1.72251 4.15638 1.65166 4.18411Z' fill='%234D4D4D'/%3E%3C/svg%3E"); */
	background-repeat: no-repeat no-repeat;
	background-position: center center;
	background-size: 12px 12px;
	background-position: right 6px center; /* Change the px value here to change the distance */
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
}
.custom-select {
    background-image: url("../assets/Collapse.svg") !important;
    background-repeat: no-repeat no-repeat;
    background-position: center center;
    background-size: 12px 12px;
    background-position: right 15px center; /* Change the px value here to change the distance */
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
}
.marginBottomForDoc{
	margin-bottom : 3px;
}
.marginTopForDoc{
	margin-top : 2.5px;
}
.marginBottomZero{
	margin-bottom : 0px;
}
.pendingEvidence{
    background-color: #8600f040;
}
.evidenceUpload{
    background: rgba(21,39,60,0.24);
}
.labelWidth{
	min-width: 7rem;
}
.framework{
	padding: 12px 170px 12px 12px;
}
.findingCategory{
	padding: 12px 80px 12px 12px;
}
.inputboxstyling1 {
  display: block;
  width: 100%;
  border-radius: 0.25rem;
  border-width: 1px;
  border-style: solid;
  --tw-border-opacity: 1;
  border-color: rgba(233, 233, 233, var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  /* background-color: rgba(255, 255, 255, var(--tw-bg-opacity)); */
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 300;
  --tw-text-opacity: 1;
  /* color: rgba(77, 77, 77, var(--tw-text-opacity)); */
}
.btnprimary1 {
	cursor: pointer;
	border-bottom-right-radius: 0.25rem;
	border-top-right-radius: 0.25rem;
	--tw-bg-opacity: 1;
	background-color: rgba(21, 42, 67, var(--tw-bg-opacity));
	padding-left: 0.625rem;
	padding-right: 0.625rem;
	padding-top: 0.625rem;
	padding-bottom: 0.625rem;
	font-family: Open Sans;
	font-size: 0.875rem;
	line-height: 1.25rem;
	font-weight: 700;
	line-height: 0.75rem;
	--tw-text-opacity: 1;
	color: rgba(255, 255, 255, var(--tw-text-opacity));
}

</style>
<script lang="ts">
import { defineComponent } from 'vue';
import breadCrumbs from '@/components/breadCrumbs.vue';
import { required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import popup from '@/components/popup.vue';
import { groupBy } from 'lodash';
import { emitter, toast } from '@/main';
import _ from 'lodash';
import moment from 'moment';
import { mapGetters } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
import commentsEditor from '@/components/commentsEditor.vue';
import Multiselect from 'vue-multiselect';
import safeHtml from "@/components/safeHtml.vue";
import dotsLoader from '@/components/dotsLoader.vue';

export default defineComponent({
	data(): any {
		return {
			v$: useVuelidate(),
			fileToUpload: null,
			selectedTabIndex: 1,
			tabHead: [
				{ displayName: 'Description', id: 1 },

				{ displayName: 'Documents', id: 2 },

				{ displayName: 'Evidence', id: 3 },

				{ displayName: 'Comments', id: 4 },

				{ displayName: 'Approvals', id: 5 },

				{ displayName: 'History', id: 6 }
			],
			routesArray: [],
			// navIndexNums: { name: '', index: '' },
			selectedControlNumbers:[],
			TaskDetailsDropDown: {
				taskStatus: '',
				assignedToDropDown: ''
			},
			userOptions:[],
			newTeamAssignee: '',
			taskStatusData: [
				{
					id: 10401,
					value: 'Open'
				},
				{
					id: 10402,
					value: 'In Progress'
				},
				{
					id: 10408,
					value: 'Waiting for Approval'
				},
				{
					id: 10419,
					value: 'ForReview'
				},
				
			],
			auditorTaskData:[
				{
					id: 10419,
					value: 'ForReview'
				},
				{
					id: 10415,
					value: 'InReview'
				},
				{
					id: 10403,
					value: 'Completed'
				}
			],
			findingCategoryData: [
				{
					id: 1,
					value: 'Minor'
				},
				{
					id: 2,
					value: 'Major'
				},
				{
					id: 3,
					value: 'OFI'
				},
				{
					id: 4,
					value: 'Observation'
				}
			],

			assignedToData: [
				{
					id: 1,
					name: 'Nikhil Kapuganti'
				},
				{
					id: 2,
					name: 'Abhiram Kadari'
				},
				{
					id: 3,
					name: 'Venkatesh Abisetty'
				}
			],
			oldComments: true,
			newHistory: true,
			comment: '',
			comments: [],
			btnDisabled: true,
			showPopUp: false,
			findingsObject: {
				findingId:'',
				frameworks: '',
				frameWorkControl:'',
				findingCategory: '',
				findingComment: '',
				findingEvidence: '',
				auditResult: ''
			},
			taskObj:{
                approvalComments : ''
            },
			findingsObjectDummy: {},
			presentAuditData: {},
			selectedOption: '',
			selectedBG: '',
			workLogObject: [],
			optionsUserArray: [],
			orgUsersList: [],
			presentAuditObject: {},
			tasksForAudit: [],
			presentTaskObject: {},
			personObj : '',
			controlsArray : [],
			controlDetailsArray : [],
            controlsArrayAllControls : [],
            controlsDetailsList : [],
			teamObj : '',
            selectedTabObj:{},
            users : [],
			allFrameWorkControls:[],
			locationObject:[],
			importpopUp: false,
			isInputFileNameValid: true,
			fileSelected: false,
			isFileValid: true,
			inputFileName: '',
			fileChanged : false,
			fileName:'',
			findingsForTask:[],
			allTasks: [],
			presentFindingTaskObj:{},
			showApprovalPopup: false,
			approvalObj : {},
			enableApproveAndReject : false,
			approvalSent : {},
			 normalJustification:{
				justification : ''
			},
			showRejectPopupForNormalApproval : false,
			showRejectPopupAtInReview : false,
			finalJustification:{
				justification : ''
			},
			clickOnButton:false,
			clickOnButtonForApproval : false,
			clickOnButtonForApproveOrReject:false,
			clickOnButtonForInProgress : false,
			searchValue:''
		};
	},
	validations() {
		return {
			inputFileName:{required},
			fileToUpload: { required },
			findingsObject: {
				findingId:{required},
				frameworks: { required },
				frameWorkControl:{required},
				findingCategory: { required },
				findingComment: { required },
				findingEvidence: { required }
				// auditResult: { required }
			}
		};
	},
	components: {
		breadCrumbs,
		popup,
		Multiselect,
		commentsEditor,
		safeHtml,
		dotsLoader
	},
	computed: {
		...mapGetters({ userInfo: 'userInfo' }),
	},
	methods: {
		onClickToRejectByJustificationAtInReview(){
		   this.showRejectPopupAtInReview = true;
		},
		onCrossBtnForFinalApproval(){
			this.showRejectPopupAtInReview = false;
			this.finalJustification.justification = '';
		},
		onCancelbtnForFinalApproval(){
	       this.onCrossBtnForFinalApproval();
	    },
		clickToRejectByJustification(){
			this.showRejectPopupForNormalApproval = true;
		},
		 onCancelbtnForNormalApproval(){
			this.onCrossBtnForNormalApproval();
		},
		 onCrossBtnForNormalApproval(){
			this.showRejectPopupForNormalApproval = false;
			this.normalJustification.justification = '';
		},
		filterTaskStatusData(data: any) {
			const startId = 10419;
			const endId = 10403;
			return data.filter((item:any) => item.id >= startId && item.id <= endId);
		},
		limitText (count:any) {
			// return `and ${count} other countries`
			console.log('countcount',count)
		},
		asyncFind(query:any){
			this.searchValue = query;
			if(query == ''){
				this.userOptions = []
			}else if(query.length > 0){
				this.userOptions = this.optionsUserArray.filter((obj:any)=>{ return obj.label.toLowerCase().includes(query.toLowerCase()) && obj.value != this.presentFindingTaskObj.auditor && obj.value!=this.userInfo._id })
			}
		},
		openApprovalpopup(){
            this.showApprovalPopup = true;
        },
		onCrossbtn(){
			this.showApprovalPopup = false;
			this.taskObj.approvalComments = '';
			
		},
		async ApprovalforInprogress(statusCode: any){
			this.clickOnButtonForInProgress = true;
            const payload: any = {
				action: 10302,
                statusCode:statusCode,
                updatedAt: moment().format('YYYY-MM-DD HH:mm:ss'),
                updatedBy:this.userInfo._id
 
            };
 
            try{
				await this.$http
                .post(`${process.env.VUE_APP_AUDIT_API_URL}/api/org/control/task/${this.$route.params.taskId}/update/approval`,payload)
                .then((result: { data: any }) => {
                   console.log('here it ISSSSSS',result.data)
                })
                if(statusCode == 10403){
                toast.success(`Approval Approved`, {
                        timeout: 1000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                });
				}else if(statusCode == 10410){
					toast.error(`Approval Rejected`, {
							timeout: 1000,
							closeOnClick: true,
							closeButton: 'button',
							icon: true
					});
				
				}
				this.enableApproveAndReject = false
				await this.getAllFindingTasks()
				this.onCrossBtnForFinalApproval()
			} catch (e) {
				toast.error(`Something went wrong`, {
				timeout: 1000,
				closeOnClick: true,
				closeButton: "button",
				icon: true,
				});
			} finally {
				this.clickOnButtonForInProgress = false;
			}
        },
        async clickOnSaveForApproval() {
			this.clickOnButtonForApproval = true;
            this.showApprovalPopup = false;
			let approvalSents:any = _.cloneDeep(this.approvalSent);
			console.log('approvalSents',approvalSents)
            const payload: any = {
                approver: approvalSents.value,
                comments: this.taskObj.approvalComments,
				createdBy: this.userInfo._id,
                createdAt: moment().format('YYYY-MM-DD HH:mm:ss'),
                
            };
			await this.updateFindingTask(6,payload)
            await this.getAllFindingTasks()
            this.taskObj.approvalComments = '';
			this.enableApproveAndReject = true
       },
		checkKey(obj: any,key: any){
			console.log("keys",obj);
			console.log("keyyyy",key);
			if(obj == '' || obj == undefined){
				return false
			}else{
				console.log("kkkkk",obj)
				const keys: any = Object.keys(obj)
				for (let i = 0; i < keys.length; i++) {
					if (keys[i] === key) {
						console.log("dsiohdsjka")
						return true;
					}
				}
				console.log("falseeeeeeee")
				return false;
			}
		},
		
		async approveOrRejectForActionTasktoComplete(statusCode : any,type :any){
			this.clickOnButtonForApproveOrReject = true;
            
            const payload: any = {
				action: 10302,
                statusCode:statusCode,
                createdAt: moment().format('YYYY-MM-DD HH:mm:ss'),
                createdBy:this.userInfo._id
            };
            try{
				await this.$http
				.post(`${process.env.VUE_APP_AUDIT_API_URL}/task/${this.$route.params.taskId}/type/${type}/update`,payload)
				.then((result: { data: any }) => {
                   console.log('here it ISSSSSS',result.data)
                })
                console.log('presentFINDING',this.presentFindingTaskObj.statusCode)
            if(statusCode == 10403){
                toast.success(`Approval Approved`, {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
				});
				await this.getFindingTaskHistoryObject()
            }else if(statusCode == 10401){
                toast.error(`Approval Rejected`, {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
				});
				await this.getFindingTaskHistoryObject() 
            }
            await this.getAllFindingTasks()
			 this.onCrossBtnForNormalApproval()
			}catch (e) {
				toast.error('error', {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
			} finally {
			    this.clickOnButtonForApproveOrReject = false;
			}
            
        },
		async getFindingTaskHistoryObject() {
			let taskId = this.$route.params.taskId;
			// console.log("taskIdddddd",taskId)
			// /api/org/task/:taskId/getHistory
			await this.$http.get(`${process.env.VUE_APP_AUDIT_API_URL}/api/org/task/${taskId}/getHistory`).then((result: any) => {
				const mainObject = result.data;
				console.log("mainObject",mainObject)
				this.workLogObject = mainObject;
				console.log('this.workLogObject', this.workLogObject);
			});
			this.workLogObject.taskHistory.sort().reverse();
		},
		
		getCategory(num: any) {
			let result = this.findingCategoryData.find((obj: any) => {
				return obj.id == num;
			});
			return result.value;
		},
		getLocationName(teamId:any) {
            let teamObj = this.users.find((obj: any) => {
                return obj._id == teamId;
            });
            if (teamObj == undefined) {
                return 'N/A';
            } else if(teamObj.entityType != undefined && teamObj.entityType == 20301) {
                return 'Global'
            }else if(teamObj.entityType != undefined && teamObj.entityType == 20303){
				return teamObj.entityName
			}else{
				return 'N/A';
			}
        },
		onCancelIcon(){
			this.importpopUp = false;
			this.clickOnButton = false;
			this.inputFileName = ''
			this.fileName = ''
			this.v$.$reset();
		},
		getStatusLabel(num:any){
			if(num == 10415 || num == 10403){
                let result = this.auditorTaskData.find((obj:any)=>{return obj.id == num})
                if(result != null || result != undefined){
                    console.log('RESULTTTTTT',result)
                    return result.value
               
                }
            }
			let result = this.taskStatusData.find((obj:any)=>{return obj.id == num})
			if(result != null || result != undefined){
                console.log('RESULTTTTTT',result)
                return result.value
               
            }
			
		},
		scrollTabIntoView(tabId : any) {
			this.$nextTick(() => {
				const tabElement = this.$el.querySelector(`.tab:nth-child(${tabId})`);
				if (tabElement) {
				tabElement.scrollIntoView({ behavior: 'smooth', inline: 'center' });
				}
			});
		},
		getTeamName(team: any) {
            let teamObj = this.users.find((obj: any) => {
                return obj._id == team;
            });
            if (teamObj == undefined) {
                return '';
            } else {
                return teamObj.displayName;
            }
        },
		colorFindingBg(status: any) {
			if (status == 10401) {
				return ['bg-lightgrey border-solid border border-disabledgrey'];
			} else if (status == 10415) {
				return ['bg-moderateColor border-solid border border-yellow'];
			} else if (status == 10403) {
				return ['bg-veryLowColor border-solid border border-lightgreen'];
			} else if (status == 10414) {
				return ['pendingEvidence border-solid border border-electricGreen'];
			} else if (status == 10402 ) {
				return ['pendingEvidence border-solid border border-electricGreen'];
			} else if (status == 10408) {
				return ['evidenceUpload border-solid border border-primary'];
			} else if (status == 10419) {
				return ['bg-forReviewBg border-solid border border-forReview'];
			}
    	},
		colorFindingText(status: any) {
			if (status == 10401) {
				return ['font-semibold text-xs text-disabledgrey'];
			} else if (status == 10415 ) {
				return ['font-semibold text-xs text-yellow'];
			} else if (status == 10403) {
				return ['font-semibold text-xs text-lightgreen'];
			} else if(status == 10414){
				return ['font-semibold text-xs mx-2 text-electricGreen']
			} else if (status == 10402 ) {
				return ['font-semibold text-xs text-electricGreen'];
			} else if(status == 10408 ){
				return ['font-semibold text-xs mx-2 text-primary']
			}else if(status == 10419){
				return ['font-semibold text-xs mx-2 text-forReview']
			}
		},
		checkAuditor(){
			if(this.presentFindingTaskObj.auditor == this.userInfo._id){
				return true
			} else if(this.presentFindingTaskObj.auditor == undefined || this.presentFindingTaskObj.auditor == null){
				return 'N/A'
			} else {
				return false
			}
		},
		async getAllActiveUsers() {
			await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/users/active/getall`).then((res: any) => {
				this.orgUsersList = res.data;
				for (var i = 0; i < this.orgUsersList.length; i++) {
					this.optionsUserArray.push({ label: this.orgUsersList[i].name, value: this.orgUsersList[i]._id });
				}
			});
			console.log('this.orgUsersList', this.orgUsersList);
		},
		async getallEntities() {
            this.locationObject = []
			let result:any
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/businessEntities/getall`).then((res: any) => {
                result = res.data;
            });
            for (var i = 0; i < result.length; i++) {
                this.locationObject.push({ label: result[i].entityName, value: result[i]._id });
            }
            console.log("locationObject",this.locationObject)
        },
		async getAllTeams() {
            this.teamObject = []
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/departments/get`).then((res: any) => {
                this.users = res.data;
            });
            console.log('users',this.users)
            for (var i = 0; i < this.users.length; i++) {
                this.teamObject.push({ label: this.users[i].teamName, value: this.users[i]._id });
            }
            console.log('teamObject',this.teamObject)
        },
		getPersonName(person: any) {
			let personObj = this.orgUsersList.find((user: any) => {
              
				return user._id == person;
			});
			if (personObj == undefined) {
				return '';
			} else {
				return personObj.name;
			}
		},
		getPerson(person: any) {
			this.personObj = this.orgUsersList.find((user: any) => {
              
				return user._id == person;
			});
			if (this.personObj == undefined) {
				return '';
			} else {
				return this.personObj.name;
			}
		},
		sortWorkLog() {
			this.newHistory = !this.newHistory;
			this.workLogObject.taskHistory.sort().reverse();
		},
		sortComments() {
			this.oldComments = !this.oldComments;
			if(this.presentFindingTaskObj.comments != undefined){
				this.presentFindingTaskObj.comments.sort().reverse();
			}
		},
		formatTime(time: any) {
			let now = moment(time);
			return now.format('DD-MM-YYYY, hh:mm:ss');
		},
		extractInitials(name: any) {
			const names = name.split(' ');
			return names
				.map((name: any) => name[0])
				.join('')
				.toUpperCase();
		},
		changeBackground() {
			if (this.TaskDetailsDropDown.taskStatus == 10401) {
				return 'bg-disabledgrey textColorWhite';
			} else if (this.TaskDetailsDropDown.taskStatus == 10415) {
				return 'bg-yellow textColorWhite';
			} else if (this.TaskDetailsDropDown.taskStatus == 10402) {
				return 'bg-electricGreen textColorWhite';
			} else if (this.TaskDetailsDropDown.taskStatus == 10408) {
				return 'bg-primary textColorWhite';
			} else if (this.TaskDetailsDropDown.taskStatus == 10403) {
				return 'bg-lightgreen textColorWhite';
			} else if (this.TaskDetailsDropDown.taskStatus == 10419) {
                return 'bg-forReview textColorWhite';
            } else {
				return '';
			}
		},
		async updateTaskStatus(status: any) {
			let payload: any = { statusCode: status };
			console.log("payloadpayloadpayload",payload)
			await this.updateFindingTask(4, payload);
		},
		async updateAssignedMember(obj: any) {
			console.log('OBJJJJJ',obj)
			let payload: any = { assignee: obj.value };
			await this.updateFindingTask(5, payload);
			if(this.presentFindingTaskObj.assignee != undefined){
                this.newTeamAssignee = this.presentFindingTaskObj.assignee
            }
		},
		handleInput() {
			this.btnDisabled = this.comment.length === 0;
		},
		async addComment() {
			if (this.comment.trim() !== '') {
				const newComment: any = {
					comment: this.comment
				};
				await this.updateFindingTask(1, newComment);
				if(this.presentFindingTaskObj.comments != undefined){
					this.presentFindingTaskObj.comments.sort().reverse();
				}
				// this.comments.push(newComment);
				this.comment = '';
				this.btnDisabled = true;
			}
		},
		clearComment() {
			this.comment = '';
			this.btnDisabled = true;
		},
		async toggleTabs(obj: any) {
            this.selectedTabIndex = obj.id;
            this.selectedTabObj = obj;
			sessionStorage.setItem('selectedTab', obj.id);
		},
		showFindingPopup() {
			this.showPopUp = !this.showPopUp;
			
		},
		onCancelButtonAddPopUp() {
			this.showPopUp = false;
			this.onCancelbtn();
		},
		openFilePopup() {
			// this.$refs.fileInput.click();
			this.importpopUp = true
		},
		
		handleFileUpload(event: any) {
            const file = this.$refs.fileInput.files[0];
            if (file) {
                this.fileName = file.name;
                this.fileToUpload = file
                console.log("file",file)
                 const fileNameParts = file.name.split('.');
                // console.log('fileNameParts1',fileNameParts)
                fileNameParts.pop(); // Remove the extension
                // console.log('fileNameParts2',fileNameParts)
                const nameWithoutExtension = fileNameParts.join('.');
                // console.log('fileNameParts',nameWithoutExtension)
                if (this.inputFileName.trim() === '') {
                this.inputFileName = nameWithoutExtension;
                }
                // console.log('inputFileName',this.inputFileName)
            } else {
                this.fileName = '';
                this.inputFileName = '';
                console.log('inputFileName',this.inputFileName)
            }
        },
		async handleFileChange(event: any) {
			console.log('this.$refs.fileInput.files[0]', this.$refs.fileInput.files[0]);
			this.clickOnButton = true;
			let data = this.$refs.fileInput.files[0];
			// this.fileToUpload = data
			this.v$.fileToUpload.$touch()
			console.log("data",data)
			if(this.fileToUpload){
			this.importpopUp = !this.importpopUp
			let fileExtension = data.name.split('.').pop();
			if (fileExtension == 'pdf') {
				const fileDataFormat = await this.getConvertDataForFile(data);
				let uuid = uuidv4();
				let payload = { fileData: fileDataFormat, name: `${uuid}.${fileExtension}` };
				let folderName = 'finding';
				try {
					await this.$http
						.post(`${process.env.VUE_APP_FILE_API_URL}/bucket/${folderName}/file/upload`, payload)
						// .post(`http://localhost:4030/services/filemanagement/bucket/${folderName}/file/upload`, payload)
						// bucket/:folderName/file/upload
						.then(async (response: any) => {
							if (response.status == 200) {
								console.log(response.data);
								let payload: any = {
									name: `${uuid}.${fileExtension}`,
									url: response.data,
									filename:this.inputFileName
								};
								await this.updateFindingTask(3, payload);
								this.inputFileName = ''
                                this.fileName = ''
                                this.v$.$reset();
							} else {
								console.log('response status', response.status);
							}
						});
				} catch (err) {
					console.error(err);
				} finally {
					this.clickOnButton = false;
				}
			} else {
				toast.error(`Upload only pdf`, {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
			}
			this.inputFileName = ''
			
			}
		},
		async updateFindingTask(type: any, payload: any) {
			console.log("payloadddd",payload)
			payload.createdBy = this.userInfo._id;
            payload.createdAt = moment().format('YYYY-MM-DD HH:mm:ss');
			if(type == 1){
				payload.additionalInfo = {comment:''};
				payload.additionalInfo.comment = payload.comment;
			}else if(type == 3){
				payload.additionalInfo = {evidence:''};
				payload.additionalInfo.evidence = payload.filename;
			}else if(type == 4){
				payload.additionalInfo = {previousStatus:'',updatedStatus:''};
				payload.additionalInfo.previousStatus = this.presentFindingTaskObj.statusCode;
				payload.additionalInfo.updatedStatus = payload.statusCode;
			}else if(type == 5){
				console.log("jdksbjhvdhjdfv",payload)
				payload.additionalInfo = {assignee:''};
				payload.additionalInfo.assignee = payload.assignee;
			}else if(type == 6){
				payload.additionalInfo = {statusCode: ''};
				// payload.additionalInfo.checkapproval = payload.checkapproval;
				payload.statusCode = payload.statusCode
			}
			try {
				console.log("jdsghcg")
				await this.$http.post(`${process.env.VUE_APP_AUDIT_API_URL}/task/${this.$route.params.taskId}/type/${type}/update`, payload).then(async (result: any) => {
					console.log('updated',payload);
					toast.info(`Updated`, {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
					await this.getAllFindingTasks();
					await this.getFindingTaskHistoryObject();
					
				});
			} catch {
				console.log("came into error not updated")
				toast.error(`Error`, {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
			} finally {
				this.clickOnButtonForApproval = false;
			}
		},
		async getConvertDataForFile(data: any) {
			return new Promise((resolve) => {
				const reader: any = new FileReader();
				reader.onload = function () {
					resolve(reader.result.replace('data:', '').replace(/^.+,/, ''));
				};
				reader.readAsDataURL(data);
			});
		},
		 colorAuditText(status: any) {
			if (status == 10401) {
				return ['font-semibold text-disabledgrey'];
			} else if (status == 10415) {
				return ['font-semibold text-yellow'];
			} else if (status == 10403) {
				return ['font-semibold text-lightgreen'];
			} else if(status == 10402){
				return ['font-semibold mx-2 text-electricGreen']
			} else if(status == 10408){
				return ['font-semibold mx-2 text-primary']
			}else if(status == 10417){
				return ['font-semibold mx-2 text-darkred']
			}
		},
		colorAuditBg(status: any) {
			if (status == 10401) {
				return ['bg-lightgrey border-solid border border-disabledgrey'];
			} else if (status == 10415) {
				return ['bg-moderateColor border-solid border border-yellow'];
			} else if (status == 10403) {
				return ['bg-veryLowColor border-solid border border-lightgreen'];
			} else if (status == 10402) {
				return ['pendingEvidence border-solid border border-electricGreen'];
			} else if (status == 10408) {
				return ['evidenceUpload border-solid border border-primary'];
			}else if (status == 10417) {
				return ['bg-veryHighColor border-solid border border-darkred'];
			}
		},
		async getAllFindingTasks() {
			await this.$http
				.get(`${process.env.VUE_APP_AUDIT_API_URL}/api/tasks/getall`)
				.then((result: any) => {
					console.log("ResultResultResult", result.data);
					this.allTasks = result.data;
					console.log("this.allTasks", this.allTasks);

					// this.presentFindingTaskObj = this.allTasks.filter((task: any) => task.actionType === 2);
					// console.log("this.presentFindingTaskObj", this.presentFindingTaskObj);

					let taskId: any = this.$route.params.taskId;
					let matchedTask: any = this.allTasks.find((task: any) =>{return task.actionType === 2 && task._id == taskId} );
					
					if (matchedTask) {
						this.presentFindingTaskObj = matchedTask;
					} else {
						this.presentFindingTaskObj = {}; 
					}

					if(this.presentFindingTaskObj.approvals != null || this.presentFindingTaskObj.approvals != undefined){
						const lastApproval = this.presentFindingTaskObj.approvals[this.presentFindingTaskObj.approvals.length - 1];
						this.approvalObj = lastApproval;
					}
					else{
						this.approvalObj = {}
					}

                        
					this.TaskDetailsDropDown.taskStatus = this.presentFindingTaskObj.statusCode;

					if(this.presentFindingTaskObj.assignee != undefined){
						let userObj:any = this.optionsUserArray.find((obj:any)=>{return obj.value == this.presentFindingTaskObj.assignee});
						this.presentFindingTaskObj.assignee = userObj;
					}
					
					console.log('HI TASK',this.presentFindingTaskObj)
				})
				.catch((error: any) => {
					console.error("Error fetching tasks:", error);
				});
		}


	},
	async mounted(){
		emitter.off('addedComments');
		emitter.on('addedComments',(data: any)=>{
			this.comment = data.comments
			this.addComment()
		})
	},
	beforeUnmount() {
		 sessionStorage.removeItem('selectedTab')
	 },
	async created() {
		const savedTabKey = sessionStorage.getItem('selectedTab');
		if (savedTabKey) {
         const savedTab = this.tabHead.find((tab: any) => tab.id == savedTabKey);
            if (savedTab) {
                this.toggleTabs(savedTab);
            }
            else{
               this.selectedTabObj = { displayName: 'Description', id: 1 };
			   this.selectedTabIndex = 1;
            }
      }
	  else{
		  this.selectedTabObj = { displayName: 'Description', id: 1 };
	   	  this.selectedTabIndex = 1;
	   }
		await this.getAllActiveUsers();
		await this.getAllTeams();
		await this.getAllFindingTasks();
		await this.getFindingTaskHistoryObject();
		// let taskId:any = this.$route.params.taskId;
		// let obj : any = this.presentFindingTaskObj.find((task: any)=>{return task._id == taskId})
		// this.presentFindingTaskObj = obj
		await this.getallEntities();
		this.findingsObjectDummy = { ...this.findingsObject };

        this.routesArray = [
            { name: 'My Findings Task', routeName: 'findingTask' },
			{ name: this.presentFindingTaskObj.taskId, routeName: 'findingTaskEdit' }
        ];
        this.navIndexNums = { index: 1, name: 'Description' };
		console.log("this is routinggggg",this.$route.params.taskId)

		let userObj:any = this.optionsUserArray.find((user: any) => {
			return user.value == this.presentFindingTaskObj.auditee;
		});

		if (userObj == undefined) {
			this.approvalSent = {}
		} else {
			this.approvalSent = userObj;
		}

		if(this.presentFindingTaskObj.comments != undefined){
			this.presentFindingTaskObj.comments.sort().reverse();
		}

		if(this.presentFindingTaskObj.assignee != undefined){
            this.newTeamAssignee = this.presentFindingTaskObj.assignee
        }
	}
});
</script>
