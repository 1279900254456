<template>
	<div class="px-5 pt-3 pb-2 mb-5 ResponsiveAudit">
		<div class="items-center justify-between flex">
			<div class="title flex items-center">
				<h1>My Finding Tasks</h1>
			</div>
			<div class="flex items-center">
				
				<button class="flex" @click="filterOpen" :class="tableRow.length == 0 ? 'btndisabled pointer-events-none' : 'filterButton'">
					Filters
					<img src="@/assets/filter.svg" class="h-3 ml-2.5" />
				</button>
			</div>
		</div>
		<div class="mt-3">
			<p class="headerText">
				An IT audit or information technology audit is an invertigation and evaluation of IT system,infrastructures,policies, and operations. Through IT audits, a company can determine if the existing IT controls protect corporate assets, ensure data integrity and align with the organization's business and financial controls.
			</p>
		</div>
		<div>
			<tableData :tableRowData="tableRow" :headersData="headersTable" :loadData="dataLoading"></tableData>
			<div v-if="toolTipActive">
				<tool-tip :tipText="toolTipText"></tool-tip>
			</div>
		</div>
	</div>
</template>
<style scoped>
.btnPrimaryPadding{
	padding-left : 16px;
	padding-right : 16px;
}
</style>
<script lang="ts">
import { defineComponent } from 'vue';
import _ from 'lodash';
import { required } from '@vuelidate/validators';
import tableData from '@/components/tableData.vue';
import useVuelidate from '@vuelidate/core';
import { mapGetters } from 'vuex';
import { emitter, toast } from '@/main';
import toolTip from '@/components/toolTip.vue'

export default defineComponent({
	data(): any {
		return { 
			v$: useVuelidate(),
			savedFindingSets: [],
			optionsUserArray: [],
			showFilters: false,
			dummyList: [],
			tableRow: [],
			optionUserArray:[],
            allTasks:[],
			teamObject:[],
			presentFindingTaskObj:{},
			users : [],
			headersTable:['taskId','action','department','location','assignee','statusCode'],
			toolTipActive:false,
			toolTipText:'',
			dataLoading:true
		};
	},
	components: {
		tableData,
		toolTip
	},
	computed: {
		...mapGetters({ userInfo: 'userInfo' }),
	},
	
	methods: {
		async getAllTeams() {
            this.teamObject = []
            // let users:any
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/departments/get`).then((res: any) => {
                this.users = res.data;
                this.teamsData = res.data;
            });
            for (var i = 0; i < this.users.length; i++) {
                this.teamObject.push({ label: this.users[i].displayName, value: this.users[i]._id });
            }
        },
		getTeamName(team: any) {
			console.log("team",team)
			console.log("dsjgjh")
            let teamObj = this.users.find((obj: any) => {
                return obj._id == team;
            });
            if (teamObj == undefined) {
                return '';
            } else {
				console.log("djkshkjsh")
                return teamObj.displayName;
            }
        },
		 async getallEntities() {
            this.locationObject = []
			let result:any
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/businessEntities/getall`).then((res: any) => {
                result = res.data;
            });
            for (var i = 0; i < result.length; i++) {
                this.locationObject.push({ label: result[i].entityName, value: result[i]._id });
            }
            console.log("locationObject",this.locationObject)
        },
		getLocationName(teamId: any) {
			let teamObj = this.users.find((obj: any) => {
                return obj._id == teamId;
            });
            if (teamObj == undefined) {
                return 'N/A';
            } else if(teamObj.entityType != undefined && teamObj.entityType == 20301) {
                return 'Global'
            }else if(teamObj.entityType != undefined && teamObj.entityType == 20303){
				return teamObj.entityName
			}else{
				return 'N/A';
			}
        },
		async getAllFindingTasks(){
			this.tableRow = []
            await this.$http
				.get(`${process.env.VUE_APP_AUDIT_API_URL}/api/tasks/getall`)
				.then((result: any) => {

                this.allTasks = result.data;
				   
                   this.allTasks.forEach((task:any) => {
						if (task.actionType === 2) {

							let departmentName:any  = task.department != undefined ? this.getTeamName(task.department) : 'N/A';
							let locationName:any  = task.department != undefined ? this.getLocationName(task.department) : 'N/A';


							task.taskId = task.taskId != undefined ? task.taskId : 'N/A'
							task.action = task.action != undefined ? task.action : 'N/A'
							task.assignee = task.assignee != undefined ? task.assignee : 'N/A',
							task.statusCode = task.statusCode != undefined ? task.statusCode : 'N/A',

							this.tableRow.push({ ...task,location: locationName ,department: departmentName});
							
						}
						
					})
                   
            });
            
        },
		
        goToFindingsInfo(obj:any){
            this.$router.push({ name: 'findingTaskEdit', params: { findingId: obj.linkedFindingId , taskId: obj._id} });
        },

		filterOpen() {
			this.showFilters = !this.showFilters;
			if (this.showFilters == true) {
                this.dummyList = this.tableRow;
            } else {
                this.tableRow = this.dummyList;
            }
			emitter.emit('visible', this.showFilters);
		},
        
	},
	async created() {

		this.dataLoading = true;

		await this.getAllTeams();
        await this.getallEntities();
        await this.getAllFindingTasks();

		this.dataLoading = false;
		
        emitter.off('clickedOnTableRow')
		emitter.on('clickedOnTableRow',async (actionObj:any)=>{
            this.goToFindingsInfo(actionObj)
		})

		emitter.off('activiteToolTip');
        emitter.on('activiteToolTip', (data: any) => {                        
            this.toolTipActive = true;
            this.toolTipText = data.tipText
        });
 
        emitter.off('deActiviteToolTip');
        emitter.on('deActiviteToolTip', (data: any) => {
            this.toolTipActive = false;
            this.toolTipText = ''
        });
		
    }  
});
</script>
 