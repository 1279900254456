<template>
	<div id="body-container" class="h-full">
		<slot />
	</div>
</template>
<style>
</style>
<script lang="ts">
export default {};
</script>

