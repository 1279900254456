<template>
	<div v-if="dotsLoading" class="mt-2">
		<div class="flex items-center justify-center w-full h-16 skeleton mt-4 tableCornerRadius bg-white">
			<dots-loader/>
		</div>
    </div>
	<div v-else class="px-5 pb-5 pt-3 ">
		<div class="mb-2">
            <breadCrumbs :routesArray="routesArray"></breadCrumbs>
        </div>
		<div class="row w-full justify-between flex">
			<h1 class="title">Create - Audit</h1>
		</div>
		<div class="pt-5">
			<div v-if="auditDiv==1" class="cardbody auditDiv1 overflow-y-scroll bg-white border-l border-r border-t border-b border-lightgrey ">
				<div class="p-5 mb-20">
					<div>
						<label class="controllabel text-xs">Audit period</label>
						<div class="row flex flex-row w-full gap-5 mt-2 mb-3">
							<div class="w-6/12">
								<input type="date" @focus="openCalendar" onkeydown = "return false" @change="checkFromPeriodFromDate" placeholder="Select Date" v-model="presentAuditObject.period.fromDate" :class="{'is-invalid': v$.presentAuditObject.period.fromDate.$error,'inputboxstylingForSelectAtPopup': presentAuditObject.period.fromDate === '','stylingForInputboxstylingForSelectAtPopup': presentAuditObject.period.fromDate !== ''}"/>
								<div v-if="v$.presentAuditObject.period.fromDate.$error" class="text-red-500">
									<div class="error-text" v-if="v$.presentAuditObject.period.fromDate.required.$invalid">Required</div>
								</div>
							</div>
							<div class="p-1">-</div>
							<div class="w-6/12">
								<input type="date" @focus="openCalendar" onkeydown = "return false" :min="presentAuditObject.period.fromDate" placeholder="Select date" v-model="presentAuditObject.period.toDate" :disabled="presentAuditObject.period.fromDate === '' ? true : false" :class="{'is-invalid': v$.presentAuditObject.period.toDate.$error,'inputboxstylingForSelectAtPopup': presentAuditObject.period.toDate === '','stylingForInputboxstylingForSelectAtPopup': presentAuditObject.period.toDate !== ''}"/>
								<div v-if="v$.presentAuditObject.period.toDate.$error" class="text-red-500">
									<div class="error-text" v-if="v$.presentAuditObject.period.toDate.required.$invalid">Required</div>
								</div>
							</div>
						</div>
					</div>
					<div class="row flex flex-row w-full gap-5 items-center">
						<div class="w-6/12">
							<label class="controllabel text-xs">Audit Start Date</label>
							<input
								type="date"
								class="mt-2 mb-3"
								@focus="openCalendar"
								onkeydown = "return false"
								:min="presentAuditObject.period.fromDate > today ? presentAuditObject.period.fromDate:today"
								v-model="presentAuditObject.startDate"
								@change="checkForStartDate"
								:disabled="presentAuditObject.period.toDate == ''"
								placeholder="Select Start Date"
								:class="{
									'is-invalid': v$.presentAuditObject.startDate.$error,'inputboxstylingForSelectAtPopup': presentAuditObject.startDate === '','stylingForInputboxstylingForSelectAtPopup': presentAuditObject.startDate !== ''
								}"
							/>
							<div v-if="v$.presentAuditObject.startDate.$error" class="text-red-500">
								<div class="error-text" v-if="v$.presentAuditObject.startDate.required.$invalid">Required</div>
							</div>
						</div>
						<div class="p-1 mt-3">-</div>
						<div class="w-6/12">
							<label class="controllabel text-xs">Audit End Date</label>
							<input
								type="date"
								class="mt-2 mb-3"
								@focus="openCalendar"
								onkeydown = "return false"
								:min="presentAuditObject.startDate"
								v-model="presentAuditObject.endDate"
								placeholder="Select End date"
								:disabled="presentAuditObject.startDate === '' ? true : false"
								:class="{
									'is-invalid': v$.presentAuditObject.endDate.$error,'inputboxstylingForSelectAtPopup': presentAuditObject.endDate === '','stylingForInputboxstylingForSelectAtPopup': presentAuditObject.endDate !== ''
								}"
							/>
							<div v-if="v$.presentAuditObject.endDate.$error" class="text-red-500">
								<div class="error-text" v-if="v$.presentAuditObject.endDate.required.$invalid">Required</div>
							</div>
						</div>
					</div>
					<div class="row flex flex-row w-full gap-12">
						<div class="w-6/12">
							<label class="controllabel text-xs">Audit Title</label>
							<input
								type="text"
								class="inputboxstyling mt-2"
								v-model.trim="presentAuditObject.title"
								placeholder="Audit Title"
								:class="{
									'is-invalid': v$.presentAuditObject.title.$error || checkAuditNames(presentAuditObject.title)
								}"
							/>
							<div v-if="v$.presentAuditObject.title.$error" class="text-red-500">
								<div class="error-text" v-if="v$.presentAuditObject.title.required.$invalid ">Required</div>			
							</div>
							<div v-if="checkAuditNames(presentAuditObject.title)" class="text-red-500">
								<div class="error-text">Audit title already exists.</div>
							</div>
						</div>
						<div class="w-6/12">
							<label class="controllabel text-xs">Audit Id</label>
							<!-- <input
								type="text"
								class="inputboxstyling mt-2 mb-3"
								v-model="presentAuditObject.auditId"
								disabled
								placeholder="Audit Id"
								:class="{
									'is-invalid': v$.presentAuditObject.auditId.$error
								}"
							/> -->
							<div class="inputboxstyling mt-2 mb-3">{{presentAuditObject.auditId}}</div>
							<div v-if="v$.presentAuditObject.auditId.$error" class="text-red-500">
								<div class="error-text" v-if="v$.presentAuditObject.auditId.required.$invalid">Required</div>
							</div>
						</div>
					</div>

					<div class="row flex flex-row w-full gap-12">
						<div class="w-6/12">
							<label class="controllabel text-xs">Team Leader</label>
							<multiselect
								v-model="presentAuditObject.teamLeader"
								:options="userOptions"
								:showNoOptions="false"
								@search-change="asyncFind"
								:limit-text="limitText"
								:multiple="false"
								:hide-selected="true"
								:close-on-select="true"
								:clear-on-select="true"
								open-direction="bottom"
								label="label"
								track-by="value"    
								:searchable="true"
								:hideArrow="false"
								placeholder="Team Leader"
								class="custom-multiselect userSelect mt-2"
								:class="{
									'is-invalid borderRed': v$.presentAuditObject.teamLeader.$error,
									'has-options': userOptions && userOptions.length > 0,
                                    'no-options': !userOptions.length &&  searchValue !== '',
								}"
							>
								<template v-slot:noResult>
									<span class="text-xs">No Members Found</span>
								</template>
							</multiselect>
							
							<div v-if="v$.presentAuditObject.teamLeader.$error" class="text-red-500">
								<div class="error-text" v-if="v$.presentAuditObject.teamLeader.required.$invalid">Required</div>
							</div>
						</div>
						<div class="w-6/12">
							<label class="controllabel text-xs">Frameworks (Maximum 3 Frameworks )</label>
							<multiselect 
								v-model="presentAuditObject.frameworks" 
								:multiple="true"
								:options="auditFrameworkArray" 
								:searchable="true" 
								:close-on-select="false" 
								:show-labels="false"
								:max="3"
								:hide-selected="true"
								placeholder="Frameworks"
								open-direction="bottom"
								class="custom-multiselect mt-2"
								:class="{
                                    'is-invalid borderRed': v$.presentAuditObject.frameworks.$error,
									'has-options': auditFrameworkArray && auditFrameworkArray.length > 0,
									'no-options':  !auditFrameworkArray.length
                                }"
							>
								<template v-slot:maxElements >
									<span class="text-xs">Maximum 3 Frameworks</span>
								</template>
								<template v-slot:noResult>
									<span class="text-xs">No Frameworks Found</span>
								</template>
							</multiselect>
							<div v-if="v$.presentAuditObject.frameworks.$error" class="text-red-500">
								<div class="error-text" v-if="v$.presentAuditObject.frameworks.required.$invalid">Required</div>
								<div class="error-text" v-if="v$.presentAuditObject.frameworks.maxThreeItems.$invalid">Max 3 frameworks can be selected</div>
							</div>
						</div>
					</div>

					<div class="row flex flex-row w-full gap-12">
						<div class="w-6/12">
							<label class="controllabel text-xs">Location</label>
							<multiselect
								v-model="presentAuditObject.location"
								:options="optionsLocationArray"
								open-direction="bottom"
								:multiple="true"
								:close-on-select="false"
								:hide-selected="true"
								:clear-on-select="true"
								label="label"
								track-by="value"
								:searchable="true"
								:hideArrow="false"
								placeholder="Location"
								class="custom-multiselect mt-2"
								:class="{
									'is-invalid borderRed': v$.presentAuditObject.location.$error,
									'has-options': optionsLocationArray && optionsLocationArray.length > 0,
									'no-options':  !optionsLocationArray.length
								}"
							>
								<template v-slot:noResult>
									<span class="text-xs">No Locations Found</span>
								</template>
							</multiselect>
							

							<div v-if="v$.presentAuditObject.location.$error" class="text-red-500">
								<div class="error-text" v-if="v$.presentAuditObject.location.required.$invalid">Required</div>
							</div>
						</div>
						<div class="w-6/12">
							<label class="controllabel text-xs">Audit Members</label>
							<multiselect
								v-model="presentAuditObject.auditMembers"
								:options="userOptions"
								:showNoOptions="false"
								@search-change="asyncFind"
								:limit-text="limitText"
								:multiple="true"
								:close-on-select="false"
								:clear-on-select="true"
								open-direction="bottom"
								:hide-selected="true"
								label="label"
								track-by="value"    
								:searchable="true"
								:hideArrow="false"
								placeholder="Audit Members"
								class="custom-multiselect userSelect mt-2 mb-3"
								:class="{
									'has-options': userOptions && userOptions.length > 0,
                                    'no-options': !userOptions.length &&  searchValue !== '',
								}"
							>
								<template v-slot:noResult>
									<span class="text-xs">No Members Found</span>
								</template>
							</multiselect>
							
						</div>
						
					</div>

				</div>
			</div>
			<div v-else-if="auditDiv==2" class="cardbody auditDivv2 overflow-y-scroll bg-white px-5 border-l border-r border-t border-lightgrey ">
				<div class="oneSideBorder pb-2 border-b border-lightgrey">
					<div class="justify-between items-center flex h-8 mt-3">
						<div class="flex my-3">
							<h2 class="header cursor-pointer">Select Teams</h2>
						</div>
						<div class="flex justify-end">
							<button class="btnPrimaryPadding" @click="addTeamsToTableView" :class="AddButtonDisableCondition() ? 'btnprimary' : 'btndisabled pointer-events-none'">Add</button>
							<!-- <button class="filterButton ml-3 flex" @click="clickOnFilters()">Filters<img src="@/assets/filter.svg" class="h-3 ml-2.5" /></button> -->
						</div>
					</div>
					<div class="rounded flex text-xs mt-3">
						<div class="w-2/5">
							<div class="border border-lightgrey option mr-1 displayContainer overflow-y-scroll rounded">
								<div v-if="showFilters" class="p-2">
									<input type="text"  v-model.trim="entityFilters.entity" class="inputboxstyling" placeholder="Search Entities" />
								</div>
								<div v-for="(location, index) in displayEntitiesList" @click="clickedLocation(location)" :class="selectedLocation == location.value ? 'bg-primary hover:bg-primary text-white' : 'hover:bg-lightgrey'" :key="index" class="rounded">
									<div class="p-3 pl-5 cursor-pointer">
										<p class="text-xs">{{ location.label }}</p>
									</div>
								</div>
							</div>
						</div>
						<div class="w-3/5">
							<div class="w-full pt-0">
								<div class="displayContainer border flex-col text-sm border-lightgrey overflow-y-scroll ml-1 rounded">
									<div v-if="showFilters" class="p-2">
										<input type="text" v-model.trim="teamFilters.team" class="inputboxstyling" placeholder="Search Teams"/>
									</div>
									<div class="h-7 flex justify-normal items-center cursor-pointer p-5 hover:bg-lightgrey" v-for="(team, index) in displayTeamList" :key="index">
										<div class="flex items-center whitespace-nowrap">
											<input type="checkbox" class="checkbox inputboxstyling" v-model="finalMappingObj[selectedLocation]" :value="team._id" :id="index" />
											<label :for="index" class="pl-3 cursor-pointer">{{team.displayName}}  ({{ team.controlCount == undefined || team.controlCount == null ? team.controlCount : 0 || team.controlCount}} {{'Controls'}})</label>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="pb-5 mb-14">
					<h2 class="header mt-2 cursor-pointer">Selected Teams</h2>
					<tableData :tableRowData="mappingTableTeamsArray" :headersData="teamDisplayHeaders"></tableData>
				</div> 

			</div>
			<div v-else-if="auditDiv==3" class="cardbody auditDiv3 overflow-y-scroll bg-white border-l border-r border-t border-lightgrey ">
				<div v-if="teamDetailsTableArray.length > 0" class="mb-10">
					<newTable :tableRowData="teamDetailsTableArray" :headersData="detailsEditHeaders" :locations="optionsLocationArray" :userArray="auditMembersArray" :departments="filteredDepartments"></newTable>
				</div> 
			</div>
		</div>
		<div class="fixed flex w-full bottom-5 right-0 bg-white rounded p-5 justify-end">
			<div v-if="auditDiv == 1 && callsComplete">
                <button class="mr-1.5 " :class="JSON.stringify(dummyAuditObj) == JSON.stringify(presentAuditObject) ? 'btndisabled pointer-events-none ' : 'btnprimary'"  @click="onCancelbtn()">Cancel</button>
                <button class="mr-1.5 btnPrimaryPadding" :class="JSON.stringify(dummyAuditObj) !== JSON.stringify(presentAuditObject) || pageType === 'edit' ? 'btnprimary' : 'btndisabled pointer-events-none '" :disabled="!(JSON.stringify(dummyAuditObj) !== JSON.stringify(presentAuditObject) || pageType === 'edit')" @click="nextFrameworkMapbtnAudit()" >Next</button>
            </div>
            <div v-else-if="auditDiv == 2">
                <button class="btnprimary btnPrimaryPadding mr-1.5"   @click="goBack()">Back</button>
                <button class="mr-1.5 btnPrimaryPadding"  :class="mappingTableTeamsArray.length > 0 ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="nextFrameworkMapbtnAudit()" >Next</button>
            </div>
            <div v-else-if="auditDiv == 3">
                <button class="btnprimary btnPrimaryPadding mr-1.5"  @click="goBack()">Back</button>
                <button class="btnprimary mr-1.5" v-if="pageType == 'add'" @click="createAudit()" :disabled="clickOnButton">Create</button>
				<button class="btnprimary mr-1.5" v-if="pageType == 'edit'" :class="JSON.stringify(dummyAuditObj) == JSON.stringify(presentAuditObject) ? 'btndisabled pointer-events-none ' : 'btnprimary'" @click="updateAudit()" :disabled="clickOnButton">Update</button>
             </div>
		</div>
	</div>
</template>
<style scoped>
.auditDiv1::-webkit-scrollbar {
	width: 0 !important;
	height: 0 !important;
	background: transparent;
}

.auditDiv1::-webkit-scrollbar-thumb {
	background: transparent;
}
.displayContainer {
    height: 276px !important;
}
.oneSideBorder {
    border-bottom-style: 1px solid #e9e9e9;
}
.drop{
	 box-shadow: 0px -25px 20px -20px rgba(0,0,0,0.05),
0px -21px 255px -255px rgba(121,119,119,0.05);
}
.cardbody{
	height: 68vh;
}
.fixBody{
	height: 12vh;
	/* width : 93%; */
	position: fixed;
    bottom: 28px;
}
.borderRed {
	border: 1px solid red !important;
}
::-webkit-scrollbar {
	width: 2px;
}
.btnPrimaryPadding{
	padding-left : 16px;
	padding-right : 16px;
}
</style>
<script lang="ts">
import { defineComponent } from 'vue';
import useVuelidate from '@vuelidate/core';
import Multiselect from 'vue-multiselect';
import { mapGetters } from 'vuex';
import { required } from '@vuelidate/validators';
import { emitter, toast } from '@/main';
import { helpers } from '@vuelidate/validators';
import { groupBy } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import tableData from '@/components/tableData.vue';
import newTable from '@/components/newTable.vue'
import { validate } from 'uuid';
import breadCrumbs from '@/components/breadCrumbs.vue';
import dotsLoader from '@/components/dotsLoader.vue';
import _ from 'lodash';
const maxThreeItems = (value:any) => value.length <= 3 

export default defineComponent({
	data(): any {
		return {
			v$: useVuelidate(),
			pageType:'',
			mandatoryKeys: ['auditPeriodStart', 'auditPeriodEnd', 'title', 'auditId', 'auditorType', 'auditor', 'criteria', 'teams'],
			fullKeys: ['auditPeriodStart', 'auditPeriodEnd', 'title', 'auditId', 'auditorType', 'auditor', 'criteria', 'teams'],
			savedAuditSets: [],
			notImportedArray: [],
			orgUsersList: [],
			optionUserArray: [],
			teamLeaderList:[],
			showFilters: true,
			finalMappingObj:{},
			filter: {},
			isSidebarToggle: true,
			selected: '',
			showAuditModal: false,
			auditList: [],
			autoSuggestion: false,
			dummyList: [],
			dummyAuditObj: [],
			dummyAuditChanged:{},
			rowData: [],
			tableRow: [],
			allDepartments: [],
			filteredDepartments:[],
			controlSets: [],
			userOptions:[],
			auditFrameworks: [],
			auditFrameworkArray: [],
			optionsLocationArray:[],
			teamDisplayHeaders:['team','entity','deleteIcon'],
			detailsEditHeaders: ['team', 'entity', 'auditee', 'auditor'],
			auditorNameValues: [
				{
					id: 1,
					value: 'Ranjith Manne'
				},
				{
					id: 2,
					value: 'Nikhil'
				},
				{
					id: 3,
					value: 'Venkatesh'
				},
				{
					id: 4,
					value: 'Jagadeesh Kuntsam'
				}
			],
			dotsLoading:false,
			// auditorTypeValue: [
			// 	{
			// 		id: 1,
			// 		value: 'Internal'
			// 	},
			// 	{
			// 		id: 2,
			// 		value: 'External'
			// 	}
			// ],
			presentAuditObject: {
				period:{
					fromDate:'',
	   				toDate:''			
				},
				startDate:'',
				endDate:'',
				title:'',
				auditId:'',
				location:[],
				frameworks:[],
				teamLeader:'',
				auditMembers:[],
				entities:[]
			},
			auditDiv:1,
			tableDisplayTeamIds:[],
			selectedLocation:'',
			mappingTableTeamsArray:[],
			teamDetailsTableArray:[],
			dummyteamDetailsTable:[],
			entityFilters : {
                entity : ''
            },
            teamFilters : {
                team : ''
            },
			existingAuditsArray:[],
			presentAuditObjForEdit:{},
			callsComplete:false,
			routesArray: [],
			auditNames:[],
			auditMembersArray:[],
			filteredAuditNames:[],
			clickOnButton: false,
			searchValue:''
		};
	},
	validations() {
		return {
			presentAuditObject: {
				period:{
					fromDate: { required },
	   				toDate: { required }			
				},
				startDate: { required },
				endDate: { required },
				title: { required },
				auditId: { required },
				location: { required },
				frameworks: { required,maxThreeItems },
				teamLeader: { required },
				// auditMembers: { required },
			},
		};
	},
	components: {
		Multiselect,
		tableData,
		newTable,
		breadCrumbs,
		dotsLoader
	},
	computed: {
		...mapGetters({ userInfo: 'userInfo' }),
		disableStatus: function (): any {
			let output;
			let val: any = [];
			Object.values(this.presentAuditObject).forEach((value: any) => {
				val.push(value);
			});
			for (let n of val) {
				if (n != '') {
					output = true;
				}
			}
			return output;
		},
		e: function (): any {
			return false;
		},
		displayEntitiesList:function():any{
			return this.presentAuditObject.location.filter((obj:any)=>{
				// return this.presentAuditObject.location
				return obj.label.toLowerCase().includes(this.entityFilters.entity);
			})
		},
		displayTeamList: function (): any {
            return this.filteredDepartments.filter((obj: any) => {
				let locationIds:any = []
				if(obj.entityType == 20303){
					// obj.entities.map((ent:any)=>{locationIds.push(ent.entity)})
					locationIds.push(obj.entityId);
				}
				let isMapped = this.mappingTableTeamsArray.some((item:any) => item.team == obj._id && item.entity === this.selectedLocation);
                return (obj.entityType == 20301 || (obj.entityType == 20303 && locationIds.includes(this.selectedLocation))) && (!isMapped) && obj.teamName.toLowerCase().includes(this.teamFilters.team) && this.displayEntitiesList.length>0
            });
        },
		today() {
			const today = new Date();
			const year = today.getFullYear();
			const month = String(today.getMonth() + 1).padStart(2, '0'); 
			const day = String(today.getDate()).padStart(2, '0');
			return `${year}-${month}-${day}`;
		}
	},
	methods: {
		limitText (count:any) {
			// return `and ${count} other countries`
			console.log('countcount',count)
		},
		asyncFind(query:any){
			this.searchValue = query;
			if(query == ''){
				this.userOptions = []
			}else if(query.length > 0){
				this.userOptions = this.optionUserArray.filter((obj:any)=>{return obj.label.toLowerCase().includes(query.toLowerCase())})
			}
		},
		checkAuditNames(auditTitle: any) {
			const trimmedAuditTitle = auditTitle.trim();
			let array: any 
			if(this.pageType == 'edit'){
				let originalObj = this.existingAuditsArray.find((obj: any)=>{return obj._id == this.$route.params.auditId})
				
				array = this.auditNames.filter((obj: any)=>{
					return obj != originalObj.title
				})
				const trimmedCheckedTitle = auditTitle.trim();
				let check: any = array.includes(trimmedCheckedTitle)
				return check;
			}else if(this.pageType == 'add'){
				let check: any = this.auditNames.includes(trimmedAuditTitle)
				return check;
			}
		},

		checkFromPeriodFromDate(){
			if(this.presentAuditObject.period.toDate != ''){
				if(this.presentAuditObject.period.fromDate > this.presentAuditObject.period.toDate){
					this.presentAuditObject.period.toDate = ''
				}
			}
		},
		openCalendar(event:any) {
			event.target.showPicker();
		},
		checkForStartDate(){
			if(this.presentAuditObject.endDate != ''){
				if(this.presentAuditObject.startDate > this.presentAuditObject.endDate){
					this.presentAuditObject.endDate = ''
				}
			}
		},
		AddButtonDisableCondition(){
			let ValueArraysOfObj:any = Object.values(this.finalMappingObj)
			if(ValueArraysOfObj.length > 0){
				let check = ValueArraysOfObj.some((arr:any)=>{ return arr.length > 0})
				return check;
			}else{
				return false;
			}
			
		},
		// clickOnFilters() {
        //     this.showFilters = !this.showFilters;
        //     console.log('yesss opened')
        //     if (this.showFilters == false) {
        //        this.teamFilters.team = ''
        //        this.entityFilters.entity = ''
        //     }
        // },
		clickedLocation(location:any){
			this.selectedLocation =  location.value;
			if(!Object.keys(this.finalMappingObj).includes(location.value)){
				this.finalMappingObj[location.value] = []
			}
		},
		addTeamsToTableView(){
			// this.mappingTableTeamsArray = []
			this.tableDisplayTeamIds = []

			let finalArray = Object.entries(this.finalMappingObj);
			for (let [location, teams] of finalArray){
				// console.log('location',location);

				let locationObj = this.optionsLocationArray.find((obj:any)=>{return obj.value == location});
				let locationOwnerObj = this.optionUserArray.find((obj:any)=>{return obj.value == locationObj.owner});
				let teamLeader = this.optionUserArray.find((obj:any)=>{return obj.value == this.presentAuditObject.teamLeader.value})
				
				let assignedTeams:any = teams
				assignedTeams.map((teamId:any)=>{
					this.tableDisplayTeamIds.push(teamId);
					
					let controlTeam:any 
					controlTeam = this.filteredDepartments.find((dept: any)=> dept._id == teamId);
					
					if (controlTeam.type == 20303){
						controlTeam = this.filteredDepartments.find((dept: any)=> dept._id == teamId && dept.entity == location);
					}

					if((controlTeam.controlCount != null || controlTeam.controlCount != undefined) && controlTeam.controlCount > 0){
						let pushObj = {team:teamId, entity:location, auditee:locationOwnerObj, auditor:teamLeader}
						this.mappingTableTeamsArray.push(pushObj)
					}else{
                        toast.error(`The Team Selected have no Controls`, {
							timeout: 3000,
							closeOnClick: true,
							closeButton: 'button',
							icon: true
						});
					}
				})
			} 

			let keys = Object.keys(this.finalMappingObj);
			keys.forEach((key:any) => {
				this.finalMappingObj[key] = [];
			}); 
			this.teamFilters.team = ''
            this.entityFilters.entity = ''
		},
		auditorValueChange(index: any) {
			const row = this.presentAuditObject.teamDetails[index];
			Array.isArray(row.auditor) ? (row.auditor = []) : (row.auditor = '');
		},
		onDeselectedTeams() {
			this.presentAuditObject.teamDetails = this.presentAuditObject.teamDetails.filter((detail: any) => this.presentAuditObject.teams.includes(detail.teams));
		},
		onClearAllTeams() {
			this.presentAuditObject.teamDetails = [];
		},
		onSelectedTeams() {
			const selectedTeams = new Set(this.presentAuditObject.teams);
			selectedTeams.forEach((team) => {
				const teamDetail = this.presentAuditObject.teamDetails.find((detail: any) => detail.teams === team);
				if (!teamDetail) {
					this.presentAuditObject.teamDetails.push({
						teams: team,
						auditorType: '',
						auditor: ''
					});
				}
			});
		},
		onCancelbtn() {
			this.v$.$reset();
			if(this.pageType == 'add'){
				Object.keys(this.presentAuditObject).forEach((prop) => {
					if(prop =='period'){
						this.presentAuditObject.period.fromDate = '';
						this.presentAuditObject.period.toDate = '';
					}else if(prop != 'auditId'){
						Array.isArray(this.presentAuditObject[prop]) ? (this.presentAuditObject[prop] = []) : (this.presentAuditObject[prop] = '');
					}
				});
			}else{
				//  this.presentAuditObject = _.cloneDeep(this.dummyAuditObj);
				this.presentAuditObject = JSON.parse(
					JSON.stringify(this.dummyAuditObj)
				);
			}
			
		},
		groupBy(arr: any, keyName: any) {
            return arr.reduce((group: any, product: any) => {
                const keyValue = product[keyName];
                group[keyValue] = group[keyValue] ?? [];
                group[keyValue].push(product);
                return group;
            }, {});
        },
		goBack(){
			if(this.auditDiv == 2){
				// this.mappingTableTeamsArray = [];
				if(this.pageType == 'add'){
					let keys = Object.keys(this.finalMappingObj);
					keys.forEach((key:any) => {
						this.finalMappingObj[key] = [];
					}); 
				}
				// else{
				// 	// this.presentAuditObject = {...this.dummyAuditObj}
				// 	this.presentAuditObject = JSON.parse(JSON.stringify(this.dummyAuditObj))
				// }
				
				this.auditDiv = 1

				this.auditMembersArray = []

			}else if(this.auditDiv == 3){

				if(this.pageType == 'add'){
					//EXISTING TABLE REMAINS WHILE ADD IN PAGE 2
					this.presentAuditObject.entities = [];
					// this.mappingTableTeamsArray = []
					// this.teamDetailsTableArray = []
				}
				// else{
				// 	//FOR EDITING TAKING BACK THE PREVIOUS TABLE IN PAGE 2
				// 	// this.presentAuditObject.entities = [...this.dummyAuditObj.entities];
				// 	this.presentAuditObject.entities = JSON.parse(JSON.stringify(this.dummyAuditObj.entities))
				// 	this.mappingTableTeamsArray = [];
				// 	this.teamDetailsTableArray = [];
				// 	this.presentAuditObject.entities.map((entityObj:any)=>{
				// 		entityObj.teams.map((teamobj:any)=>{
				// 			let findAUditeeobj = this.optionUserArray.find((obj:any)=>{return obj.value == teamobj.auditee});
				// 			let findAuditorobj = this.optionUserArray.find((obj:any)=>{return obj.value == teamobj.auditor});
				// 			teamobj.auditee = findAUditeeobj;
				// 			teamobj.auditor = findAuditorobj;
				// 			this.mappingTableTeamsArray.push(teamobj)
				// 		})
				// 	})

				// }
				this.clickedLocation(this.displayEntitiesList[0]);
				this.auditDiv = 2

			}
		},
		async nextFrameworkMapbtnAudit() {
			console.log("this.presentAuditObject",this.presentAuditObject)
			let checkAuditName = this.auditNames.includes(this.presentAuditObject.title)
			console.log("this.checkAuditName",checkAuditName)
			this.v$.presentAuditObject.$touch();
			if(!this.v$.presentAuditObject.$invalid && (this.auditNames.length == 0 || !this.checkAuditNames(this.presentAuditObject.title))){
				if(this.auditDiv == 1){
					
					this.clickedLocation(this.displayEntitiesList[0]);
					if(this.pageType == 'edit'){
						this.mappingTableTeamsArray = [];
						this.presentAuditObject.entities.map((entityObj:any)=>{
							entityObj.teams.map((teamobj:any)=>{
								// let findAUditeeobj = this.optionUserArray.find((obj:any)=>{return obj.value == teamobj.auditee});
								// let findAuditorobj = this.optionUserArray.find((obj:any)=>{return obj.value == this.presentAuditObject.teamLeader.value});
								// teamobj.auditee = findAUditeeobj;
								// teamobj.auditor = findAuditorobj;
								this.mappingTableTeamsArray.push(teamobj)
							})
						})
						
					}
					// this.dummyAuditChanged = {...this.presentAuditObject}
					// this.auditDiv = 2

					this.auditMembersArray = [...this.presentAuditObject.auditMembers]
					let memeberIds:any = []
					this.presentAuditObject.auditMembers.map((obj:any)=>{
						memeberIds.push(obj.value);
					})
					if(!memeberIds.includes(this.presentAuditObject.teamLeader.value)){
						this.auditMembersArray.push({...this.presentAuditObject.teamLeader});
					}
					this.auditDiv = 2

				}else if(this.auditDiv == 2){
					this.presentAuditObject.entities = [];
					this.teamDetailsTableArray = [];
					this.dummyteamDetailsTable = [];
					

					let groupByObjectForTeamsAndLocation:any = this.groupBy(this.mappingTableTeamsArray,'entity')

					console.log('ubsdusudbsjbdjb',groupByObjectForTeamsAndLocation)
					console.log('sldknsiud',this.finalMappingObj)
					
					let entityKeys = Object.keys(groupByObjectForTeamsAndLocation);
					
					for (let key of entityKeys){
						if(groupByObjectForTeamsAndLocation[key] != undefined && groupByObjectForTeamsAndLocation[key].length > 0){
							let pushObj = {entity:key,teams:groupByObjectForTeamsAndLocation[key]};
							console.log('pushObjpushObj',pushObj)
							this.presentAuditObject.entities.push(pushObj)
							groupByObjectForTeamsAndLocation[key].map((obj:any)=>{
								this.teamDetailsTableArray.push(obj)
								this.dummyteamDetailsTable.push({...obj});
							})
						}else{
							console.log('no teams for this entity')
						}
					}
					this.auditDiv = 3
				}
			}
		},
		async createAudit(){
			console.log('OBJ TO DB AS NEW AUDIT',this.presentAuditObject)
			emitter.emit('checkAuditValidation','add')
		},
		async updateAudit(){
			emitter.emit('checkAuditValidation','edit')
		},
		async getallEntities(){
			this.optionsLocationArray = []
			await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/businessEntities/getall`).then((result: any)=>{
				console.log("resultLocations",result.data)
				console.log("resultTeams",this.filteredDepartments)
				result.data.map((obj:any)=>{
					let sendObj = {
						value:obj._id,
						label:obj.entityName,
						owner:obj.owner
					}
					this.optionsLocationArray.push(sendObj)
				})

			})
		},
		async getAllActiveUsers() {
			await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/users/active/getall`).then((res: any) => {
				console.log('res.data', res.data);
				this.orgUsersList = res.data
				// .filter((user: any) => {
				// 	return user.isActive == true;
				// });
				for (var i = 0; i < this.orgUsersList.length; i++) {
					this.optionUserArray.push({ label: this.orgUsersList[i].name, value: this.orgUsersList[i]._id });
				}
			});
			console.log('this.orgUsersList', this.orgUsersList);
		},
		async getAllTeams() {
		 let countData:any
			try {
			await this.$http.get(`${process.env.VUE_APP_CONTROLS_API_URL}/api/org/departments/mappedcontrols/count`).then((result: { data: any }) => {
				countData = result.data;
			});
			} catch (e) {
			toast.error('error', {
				timeout: 1000,
				closeOnClick: true,
				closeButton: 'button',
				icon: true
			});
			}

			await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/departments/get`).then((res: any) => {
				this.allDepartments = [...res.data];
				this.filteredDepartments = res.data.filter((obj:any)=>{
					return Object.keys(obj).includes('entityType')
				})
			});
			// console.log('this.filteredDepartments', this.filteredDepartments);

			this.filteredDepartments.forEach((obj:any)=>{
				if(obj.entityType == 20301){
				let myObj = countData.find((countObj:any)=>{return countObj._id == obj._id});

				if(myObj != undefined && myObj.controlCount!= undefined){
					obj.controlCount = myObj.controlCount
				}else{
					obj.controlCount = 0
				}
				}else if(obj.entityType == 20303){
				let myObj = countData.find((countObj:any)=>{return countObj._id == obj._id});

				if(myObj != undefined && myObj.controlCount!= undefined){
					obj.controlCount = myObj.controlCount
				}else{
					obj.controlCount = 0
				}
				}
			})
		},
		async getControls() {
			await this.$http
				// .get(`http://localhost:4030/services/controls/api/org/allControls`)
				.get(`${process.env.VUE_APP_CONTROLS_API_URL}/api/org/allControls`)
				.then((result: { data: any }) => {
					this.controlSets = result.data.filter((obj:any)=>{ return Object.keys(obj).includes('controlFrameWork')})
					const groupedControlsFrameworks = groupBy(this.controlSets, 'controlFrameWork');
					const listOfFrameworks = Object.keys(groupedControlsFrameworks);
					listOfFrameworks.forEach((key) => {
						if(key !=""){
							// let obj = {value:key,label:key}
							this.auditFrameworkArray.push(key);
						}
						
					});
					this.auditFrameworkArray.push('Internal Controls')
					
				})
				.catch((error: any) => {
					this.isLoading = false;
					(this.showLoader = false),
						toast.error(` ${error}`, {
							timeout: 1000,
							closeOnClick: true,
							closeButton: 'button',
							icon: true
						});
				});
		},
		async getAllAudits(){
			await this.$http.get(`${process.env.VUE_APP_AUDIT_API_URL}/api/org/audits/getAll`).then((result: any) => {
				this.existingAuditsArray = [...result.data];
				this.existingAuditsArray.forEach((name: any)=>{
					this.auditNames.push(name.title)
				})
				console.log('AUDITNAMES',this.auditNames)
			})
		},
		async createNewAudit(){
			this.dotsLoading = true
			let copyOfpresentAuditObject:any = _.cloneDeep(this.presentAuditObject);
			copyOfpresentAuditObject.location = copyOfpresentAuditObject.location.map((obj:any) => obj.value);
			copyOfpresentAuditObject.auditMembers = copyOfpresentAuditObject.auditMembers.map((obj:any) => obj.value);
			copyOfpresentAuditObject.teamLeader = copyOfpresentAuditObject.teamLeader.value;

			copyOfpresentAuditObject.entities.map((entityObj:any)=>{
				entityObj.teams.map((obj:any)=>{
					obj.auditee = obj.auditee.value;
					obj.auditor = obj.auditor.value;
				})
			})

			copyOfpresentAuditObject.statusCode = 10401;
			copyOfpresentAuditObject.createdAt = new Date();
			copyOfpresentAuditObject.createdBy = this.userInfo._id
			try{
				await this.$http.post(`${process.env.VUE_APP_AUDIT_API_URL}/api/org/audit/create`,[copyOfpresentAuditObject]).then((res: any) => {
					console.log('res',res)
					toast.info(`Uploaded succesfully`, {
						closeOnClick: true,
						closeButton: 'button'
					});
					if(res.status == 200){
						this.dotsLoading = false
					}
					this.$router.push({name:'audit'});
				})
			}catch{
				toast.error(`Error`, {
						closeOnClick: true,
						closeButton: 'button'
					});
			}finally {
				this.clickOnButton = false;
			}
			
		},
		async updatePresentAudit(){
			this.dotsLoading = true
            let copyOfpresentAuditObject:any = _.cloneDeep(this.presentAuditObject);
			const auditId:any = copyOfpresentAuditObject._id;
			delete copyOfpresentAuditObject._id;

			copyOfpresentAuditObject.location = copyOfpresentAuditObject.location.map((obj:any) => obj.value);
			copyOfpresentAuditObject.auditMembers = copyOfpresentAuditObject.auditMembers.map((obj:any) => obj.value);
			copyOfpresentAuditObject.teamLeader = copyOfpresentAuditObject.teamLeader.value;

			copyOfpresentAuditObject.entities.map((entityObj:any)=>{
				entityObj.teams.map((obj:any)=>{
					obj.auditee = obj.auditee.value;
					obj.auditor = obj.auditor.value;
				})
			})
			
			copyOfpresentAuditObject.updatedAt = new Date();
			copyOfpresentAuditObject.updatedBy = this.userInfo._id;

			let payloadObj:any = {...copyOfpresentAuditObject};

			try{
				await this.$http.post(`${process.env.VUE_APP_AUDIT_API_URL}/api/org/audit/${auditId}/update`,payloadObj).then((res: any) => {
					console.log('res',res.status)
					toast.info(`Updated succesfully`, {
						closeOnClick: true,
						closeButton: 'button'
					});
					if(res.status == 200){
						this.dotsLoading = false
					}
					this.$router.push({name:'audit'});
				})
			}catch{
				toast.error(`Error`, {
					closeOnClick: true,
					closeButton: 'button'
				});
			}finally {
				this.clickOnButton = false;
			}	
		},
		modifyAuditForEdit(obj:any){
			let presentObj:any = {...obj}
			console.log('camepresentObjpresentObj',presentObj)
			let AuditlocationIds = [...presentObj.location];
			presentObj.location = [];

			let AuditMembersIds = [...presentObj.auditMembers];
			presentObj.auditMembers = [];

			AuditlocationIds.map((id:any)=>{
				let findObj:any = this.optionsLocationArray.find((obj:any)=>{return obj.value == id;})
				findObj!=undefined ? presentObj.location.push(findObj) : '';
			})

			AuditMembersIds.map((id:any)=>{
				let findUserObj:any = this.optionUserArray.find((obj:any)=>{return obj.value == id;})
				findUserObj!=undefined ? presentObj.auditMembers.push(findUserObj) : '';
			})

			let personId = presentObj.teamLeader;
			let findTl:any = this.optionUserArray.find((obj:any)=>{return obj.value == personId;});
			findTl!=undefined ? presentObj.teamLeader = findTl : '';

			presentObj.entities.map((entityObj:any)=>{
				entityObj.teams.map((detailObj:any)=>{
					let findAUditeeobj = this.optionUserArray.find((obj:any)=>{return obj.value == detailObj.auditee});
					let findAuditorobj = this.optionUserArray.find((obj:any)=>{return obj.value == detailObj.auditor});
					detailObj.auditee = findAUditeeobj;
					detailObj.auditor = findAuditorobj;
				})
			})

			return presentObj
		}
	},
	async mounted(){
		emitter.off('auditValidationDone')
		emitter.on('auditValidationDone', async (action:any) => {
            console.log('Done You can calll APIIII')
			if(action == 'add'){
				this.clickOnButton = true;
				await this.createNewAudit();
			}else{
				this.clickOnButton = true;
				await this.updatePresentAudit()
			}
			
        });

		emitter.off('deleteRowFromTable')
		emitter.on('deleteRowFromTable', (data:any) => {
			let afterDeleteArray = this.mappingTableTeamsArray.filter((obj:any)=>{
				return !(data.team == obj.team && data.entity==obj.entity)
			});
			this.mappingTableTeamsArray = [...afterDeleteArray]
        });
	},
	async created() {
		await this.getControls();
		await this.getallEntities();
		await this.getAllActiveUsers();

		if(this.$route.path.includes('/create')){
			this.routesArray = [
                { name: 'Audit', routeName: 'audit' },
                { name: 'Create-Audit', routeName: '' }
            ];
			this.pageType = 'add';

			let uuid = uuidv4();
             const truncatedUuid = uuid.replace(/-/g,'').substring(0, 8).toUpperCase();

			this.presentAuditObject.auditId = truncatedUuid;
			this.dummyAuditObj = JSON.parse(JSON.stringify(this.presentAuditObject));
			await this.getAllAudits();
		}else{
			this.routesArray = [
                { name: 'Audit', routeName: 'audit' },
                { name: 'Edit-Audit', routeName: '' }
            ];
			this.pageType = 'edit';
			await this.getAllAudits();
			let present = this.existingAuditsArray.find((obj:any)=>{return this.$route.params.auditId==obj._id});
			let modified:any = await this.modifyAuditForEdit(present)
			this.presentAuditObject = {...modified};
			this.dummyAuditObj = JSON.parse(JSON.stringify(this.presentAuditObject))
			// this.dummyAuditObj =
		}
		await this.getAllTeams();

		this.callsComplete = true;

		this.auditDiv = 1
		
	}
});
</script>

