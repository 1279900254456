
<template>
     <!-- <div v-if="dotsLoading" class="mt-2">
		<div class="flex items-center justify-center w-full h-16 skeleton mt-4 tableCornerRadius bg-white">
			<dots-loader/>
		</div>
	</div> -->
	<div class="tableCornerRadius bg-white">
	<div :class="pageData!=undefined && pageData == 4 ? 'fixHeightTable flex flex-col justify-between':''">
		<div class="table-container">
			<table>
			<thead>
				<th scope="col" v-for="presentColumn in columnNames" :key="presentColumn" :class="getColumnHeadWidth(presentColumn)" class="bg-white capitalize" @click="sort(presentColumn)">
                 <div class="flex" :class="getPaddingForColumn(presentColumn)">
                        <div >{{ getColumnLabel(presentColumn) }}</div>
                        <div class="sort cursor-pointer" :class="className == presentColumn ? classSort : 'opacity-50'" v-if="getColumnLabel(presentColumn) != ''"></div>
                </div>
                </th>
				<tr v-if="visibility">
                    <td v-for="(presentColumn, index) in columnNames" :key="index">
                        <!-- Dropdown -->
                        <select v-if="isColumnArrayPresent(presentColumn)" class="statusFilter" :class="[filters[presentColumn] == null ? 'inputboxstylingForSelect' : 'stylingForInputboxstylingForSelect',selectInputStyling(presentColumn)]" v-model="filters[presentColumn]">
                            <option :value="filters.presentColumn" selected hidden disabled>Select</option>
                            <option class="optionColors" v-for="option in getDropdownOptionsForFilter(presentColumn)" :key="option.id" :value="option.id">{{ option.value }}</option>
                        </select>
                        <!-- No Input -->
                        <div v-else-if="isFilterNotNecessary(presentColumn)"></div>
                        <div v-else-if="presentColumn == 'findingId' || presentColumn == 'taskId'" >
							<input type="text" class="searchForInputBoxStyling marginForIdInFilters" v-model.trim="filters[presentColumn]" placeholder="Search" />
						</div>
                        <!-- Text Input -->
                        <input v-else type="text" class="searchForInputBoxStyling" v-model.trim="filters[presentColumn]" placeholder="Search" />
                    </td>
                </tr>
			</thead>

			<tbody v-if="loadData == true">
				<tr class="cursor-pointer empty rowHeight">
					<td class="relative h-5" :colspan="columnNames.size">
						<div class="flex items-center justify-center w-full h-16 skeleton">
							<dots-loader/>
						</div>
					</td>
				</tr>
			</tbody>

			<tbody :class="pageData!=undefined && pageData == 4 ? 'emptyFixed':''" v-else-if="sortedData.length == 0">
                <tr class="cursor-pointer rowHeight">
                    <td class="relative h-10" :colspan="columnNames.size">
                        <div class="flex justify-center"><p class="text-xs font-normal">No Records Found</p></div>
                    </td>
                </tr>
            </tbody>
			
			<tbody class="nonEmptyTable" v-else>
                <tr v-for="(row, index) in sortedData" :key="row" class="cursor-pointer rowHeight" >
                    <td v-for="presentColumn in columnNames" :key="presentColumn" @mouseout="doFalse()"  @click="clickedOnActionButton(row, index, presentColumn)" class="cursor-pointer relative">
                        <!-- <div class=" ml-3 rounded h-5 w-24" v-if="row[presentColumn] == ''">{{ '-' }}</div> -->
                        <div v-if="isColumnPresentForLabel(presentColumn)">
                            <div class="flex items-center justify-center rounded h-5 w-24" :class="bgColorForStatus(row[presentColumn])">
                                <div :class="textColorForStatus(row[presentColumn])">
                                    {{getStatusLabelFromColumn(row[presentColumn],presentColumn)}}
                                </div>
                            </div>
                        </div>
						 <div v-else-if="presentColumn == 'findingStatus' || presentColumn == 'statusCode'">
                            <div class="flex items-center justify-center rounded h-5 w-24" :class="bgColorForFindingStatus(row[presentColumn])">
                                <div :class="textColorForFindingStatus(row[presentColumn])">
									<!-- {{   row[presentColumn],presentColumn }} -->
                                    {{getStatusLabelFromColumn(row[presentColumn],presentColumn)}}
                                </div>
                            </div>
                        </div>
						<div v-else-if="presentColumn == 'taskStatus' || presentColumn == 'statusCode'">
                            <div class="flex items-center justify-center rounded h-5 w-32 ml-1.5" :class="colorAuditBg(row[presentColumn])">
								<div :class="colorAuditText(row[presentColumn])">
									{{getStatusLabelFromColumn(row[presentColumn],presentColumn)}}
								</div>
							</div>
						</div>
						<div v-else-if="presentColumn == 'findingCategory'">
							{{getStatusLabelFromColumn(row[presentColumn],presentColumn)}}
						</div>
						<div v-else-if="presentColumn == 'startDate' || presentColumn == 'endDate'  ">
							{{changeDateFormat(row[presentColumn])}}
                        </div>
						<div v-else-if="presentColumn == 'auditPeriod' " class="w-36" :class="presentColumn == 'startDate'?'pl-3':''">
							{{row[presentColumn]}}
                        </div>
						<div v-else-if="presentColumn == 'frameWorkControl'" :class="getBorder()" class="text-xs px-2 py-0.5 font-opensans bg-white font-bold h-auto w-fit flex items-center" >
							{{row[presentColumn]}}
						</div>
						<div v-else-if="checkuserData(presentColumn)">
                            <div v-if="presentColumn == 'team' "><p class="cursor-pointer text-xs">{{ getTeamName(row[presentColumn]) }}</p></div>
							 <div v-else-if="presentColumn == 'entity'"><p class="cursor-pointer text-xs">{{ getLocationName(row[presentColumn]) }}</p></div>
							 <div v-else-if="presentColumn == 'auditor'">
								<div class="cursor-pointer text-xs table-ellipsis" @mousemove="toolTip(presentColumn, $event, index, row, 'text')">
									{{ getOwnerName(row[presentColumn]) }}
								</div>
							</div>
							<div v-else-if="presentColumn == 'assignee'">
								<div class="cursor-pointer text-xs table-ellipsis" @mousemove="toolTip(presentColumn, $event, index, row, 'text')">
									{{ getOwnerName(row[presentColumn]) }}
								</div>
							</div>
							<!-- <div v-if="pop == true && sortedData[tipIndex][presentColumn] == tipText && tipColumn == presentColumn && tipIndex == index">
                                    <tool-tip :columnName="presentColumn" :tipText="getOwnerName(tipText)" :styling="styling"></tool-tip>
                            </div> -->
                        </div>
						<div v-else-if="presentColumn=='deleteIcon'">
                            <div class="flex justify-start items-center h-5">
                                    <img src="@/assets/deleteIcon.svg" class="h-5 w-5 cursor-pointer" @click="clickedOnDeleteButton(row)"/>
                                </div>
                        </div>
                        <div v-else-if="presentColumn == 'actionButtonAtAudits'" @click.stop="calculateDropdownPosition($event,presentColumn,index)">
                            <fieldset>
                                <div class="flex justify-end items-center w-5 h-5 ImageResponsive">
                                    <img src="@/assets/ActionIcon.svg" class="h-5 w-5 cursor-pointer"/>
                                </div>
                                <ul class="card shadow-sm zIndexingForActionDropDown absolute cursor-pointer actionButtonStyling" :class="isNearBottom(index) ? 'dropdown-up' : 'zIndexingForActionDropDown1'" :style="isNearBottom(index) ? {} : {left: dynamicLeftPosition + 'px',top: dynamicTopPosition + 'px',display: showDropDown ? 'block' : 'none'}" v-if="showDropDown && selectedRowIndex == index" >
                                    <li class="cursor-pointer lastChild border-b border-lightgrey hover:bg-lightgrey h-7 flex items-center" v-for="(option, index) in optionsValue" @click="optionClickedOnAction(option.value,row)" :key="index" >
                                        
										<div class="flex items-center" v-if="option.value == 'Edit'">
											<img src="@/assets/editIcon.svg" class="mx-2.5 cursor-pointer h-5 w-5 " />
											{{ option.value }}
										</div>
										<div class="flex items-center" v-if="option.value == 'View'">
											<img src="@/assets/view.svg" class="mx-2.5 cursor-pointer h-5 w-5 " />
                                            {{ option.value }}
                                        </div>
										<div class="flex items-center" v-if="option.value == 'Controls'">
											<img src="@/assets/controls.svg" class="mx-2.5 cursor-pointer h-5 w-5 " />
                                            {{ option.value }}
                                        </div>
										<div class="flex items-center" v-if="option.value == 'Reports'">
											<img src="@/assets/reports.svg" class="mx-2.5 cursor-pointer h-5 w-5 " />
                                            {{ option.value }}
                                        </div>
                                    </li>
                                </ul>
                            </fieldset>
                        </div>
						<!-- <div v-else-if="presentColumn == 'actionButtonAtFindings'">
                            <fieldset>
                                <div class="flex justify-end items-center w-5 h-5 ImageResponsive">
                                    <img src="@/assets/ActionIcon.svg" class="h-5 w-5 cursor-pointer" />
                                </div>
                                <ul class="card shadow-sm zIndexingForActionDropDown absolute cursor-pointer actionButtonStyling" v-if="showDropDown && selectedRowIndex == index" >
                                    <li class="cursor-pointer border-b border-lightgrey hover:bg-lightgrey h-7 flex items-center" v-for="(option, index) in ActionButtonAtFindings" @click="optionClickedOnActionAtFindings(option.value,row)" :key="index" >
                                        
										<div class="flex items-center" v-if="option.value == 'View'">
											<img src="@/assets/view.svg" class="mx-2.5 cursor-pointer h-5 w-5 " />
											{{ option.value }}
										</div>
                                    </li>
                                </ul>
                            </fieldset>
                        </div>
						<div v-else-if="presentColumn == 'actionButtonAtControlsTasks'">
                            <fieldset>
                                <div class="flex justify-end items-center w-5 h-5 ImageResponsive">
                                    <img src="@/assets/ActionIcon.svg" class="h-5 w-5 cursor-pointer" />
                                </div>
                                <ul class="card shadow-sm zIndexingForActionDropDown absolute cursor-pointer actionButtonStyling" v-if="showDropDown && selectedRowIndex == index" >
                                    <li class="cursor-pointer border-b border-lightgrey hover:bg-lightgrey h-7 flex items-center" v-for="(option, index) in ActionButtonAtControlsTasks" @click="optionClickedOnActionAtControlsTasks(option.value,row)" :key="index" >
                                        
										<div class="flex items-center" v-if="option.value == 'View'">
											<img src="@/assets/view.svg" class="mx-2.5 cursor-pointer h-5 w-5 " />
											{{ option.value }}
										</div>
                                    </li>
                                </ul>
                            </fieldset>
                        </div>
						<div v-else-if="presentColumn == 'ActionButtonAtFindingsTasks'">
                            <fieldset>
                                <div class="flex justify-end items-center w-5 h-5 ImageResponsive">
                                    <img src="@/assets/ActionIcon.svg" class="h-5 w-5 cursor-pointer" />
                                </div>
                                <ul class="card shadow-sm zIndexingForActionDropDown absolute cursor-pointer actionButtonStyling" v-if="showDropDown && selectedRowIndex == index" >
                                    <li class="cursor-pointer border-b border-lightgrey hover:bg-lightgrey h-7 flex items-center" v-for="(option, index) in ActionButtonAtFindingsTasks" @click="optionClickedOnActionAtActionTasks(option.value,row)" :key="index" >
                                        
										<div class="flex items-center" v-if="option.value == 'View'">
											<img src="@/assets/view.svg" class="mx-2.5 cursor-pointer h-5 w-5 " />
											{{ option.value }}
										</div>
                                    </li>
                                </ul>
                            </fieldset>
                        </div> -->

                        <div v-else>
                            <div class="table-ellipsis">
                                <div  @mousemove="toolTip(presentColumn, $event, index, row, 'text')">
                                    {{ row[presentColumn] }}
                                </div>
                            </div>
                            <!-- <div v-if="pop == true && sortedData[tipIndex][presentColumn] == tipText && tipColumn == presentColumn && tipIndex == index">
                                <tool-tip :columnName="presentColumn" :tipText="tipText" :styling="styling"></tool-tip>
                            </div> -->
                        </div>
                    </td>
                </tr>
            </tbody>
		</table>
		</div>
		<pagination :dynamicPagination="dynamicPagination" @currentPageUpdate="paginationUpdations" :sortedData="sortedData" :pageData="pageData"></pagination>
	</div>
	</div>
</template>
<style scoped>
.inputboxstylingForSelect .optionColors{
   color : #4D4D4D !important;
}
.fixHeightTable{
	height: 353px !important;
}
.emptyFixed:last-child  {
	border-bottom: 1px solid #e9e9e9;
}
.nonEmptyTable:last-child  {
	border-bottom: 1px solid #e9e9e9;
}
.ellipsis-container {
	text-overflow: ellipsis;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	line-height: 1.5em;
	max-height: 3em;
}
.table-ellipsis {
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}
.statusFilter{
	width : 99px !important
}
.statusWidthForTask{
	width : 134px !important
}
.actionButtonStyling {
    left: -18px;
}
.lastChild:last-child {
    border: 0px;
}
/* .rowHeight{
  height: 40px;
} */
 .actionButtonWidth{
	width : 60px;
 }
 .numberBorder{
    border-top: 1px solid #e9e9e9;
    border-right: 1px solid #e9e9e9;
    border-bottom: 1px solid #e9e9e9;
}
.numberNavy{
    border-left: 1px solid #152A43;
    border-width: 2px;
}
.zIndexingForActionDropDown{
	z-index : 65;
	width : 100px;
	margin-left : -40px;
	position: absolute;
    left: -8px;
}
.zIndexingForActionDropDown1{
	z-index : 65;
	width : 100px;
	margin-left : -40px;
	position: fixed;
}
.pendingEvidence{
    background-color: #8600f040;
}
.evidenceUpload{
    background: rgba(21,39,60,0.24);
}
.widthFor1stAudit{
	width: 98px;
    margin-left: -3px;
}
.marginForIdInFilters{
	margin-left : -6px;
}
.w-12{
	width: 60px !important;
}
.width25{
	width : 25%;
}

</style>
<script lang="ts">
import { emitter,toast } from '@/main';
import _ from 'lodash';
import { defineComponent } from 'vue';
import toolTip from '@/components/toolTip.vue';
import pagination from '@/components/pagination.vue'
import dotsLoader from '@/components/dotsLoader.vue';
import { mapGetters } from 'vuex';

export default defineComponent({
	props: ['tableRowData', 'headersData','pageData','loadData'],
	data(): any {
		return {
			tableHead: [],
			tableRow: [],
			searchTableRow: [],
			visibility: false,
			className: '',
			classSort: '',
			currentSort: 'name',
			currentSortDir: 'asc',
			currentPage: '',
			pageSize: '',
			dynamicPagination: '',
			pop: false,
			styling: { left: '', bottom: '' },
			tipText: '',
			tipColumn: '',
			tipIndex: '',
			filters: {},
			showDropDown: false,
			selectedRowIndex: '',
			optionsUserArray : [],
			teamObject : [],
			locationObject : [],
			showBlock: false,
            dynamicLeftPosition: 0,
            dynamicTopPosition: 0,
      		isDropdownUp: false,
			statusType: [
				{
					id: 10401,
					value: 'Not started'
				},
				{
					id: 10402,
					value: 'In progress'
				},
				{
					id: 10403,
					value: 'Closed'
				}
			],
			optionsValue: [
                {
                    id: 1,
                    value: 'Edit',
                },
                {
                    id: 2,
                    value: 'View',
                },
                {
                    id: 5,
                    value: 'Controls',
                },
				{
					id: 6,
					value: 'Reports',
				}
            ],
			columns: [
                { key: 'auditId', value: 'Audit ID' },
                { key: 'title', value: 'Title' },
                { key: 'auditPeriod', value: 'Audit Period' },
                { key: 'startDate', value: 'Start Date' },
                { key: 'endDate', value: 'End Date' },
                { key: 'auditor', value: 'Auditor' },
				{ key: 'auditor1', value: 'Auditor' },
                { key: 'auditStatus', value: 'Status' },
				{ key: 'findingStatus', value: 'Status' },
				{ key: 'findingId', value: 'Id' },
				{ key: 'taskId', value: 'Task No.' },
				{ key: 'taskStatus', value: 'status' },
				{ key: 'findingComment', value: 'finding' },
				{ key: 'auditName', value: 'Audit' },
				{ key: 'findingCategory', value: 'Category' },
				{ key: 'teamName', value: 'team' },
				{ key: 'entityName', value: 'entity' },
				{ key: 'teamName1', value: 'team' },
				{ key: 'entityName1', value: 'entity' },
				{ key: 'controlNumber', value: 'control' },
                { key: 'controlName', value: 'title' },
                { key: 'frameworks', value: 'framework' },
				{ key: 'frameWorkControl', value: 'control' },
				{ key: 'statusCode', value: 'Status' },
				{ key: 'department', value: 'Team' },
				{ key: 'assignee', value: 'Assigned To' },
				
            ],
			dropdownOptions:[
				{
                    key: 'auditStatus',
                    value: [
                       	{
							id: 10401,
							value: 'Not started'
						},
						{
							id: 10402,
							value: 'In progress'
						},
						{
							id: 10403,
							value: 'Closed'
						},
						{
							id: 10418,
							value: 'Terminated'
						},
                    ]
                
				},
				{
                    key: 'findingStatus',
                    value: [
                       	{
							id: 10401,
							value: 'Open'
						},
						{
							id: 10415,
							value: 'In Review'
						},
						{
							id: 10402,
							value: 'In Progress'
						},
						{
							id: 10406,
							value: 'Partial'
						},
						{
							id: 10403,
							value: 'Closed'
						},
						{
							id: 10418,
							value: 'Terminated'
						},
                    ]
                
				},
				{
                    key: 'taskStatus',
                    value: [
						{
							id: 10401,
							value: 'Not Started'
						},
						{
							id: 10414,
							value: 'Pending Evidence'
						},
						{
							id: 10415,
							value: 'InReview'
						},
						{
							id: 10416,
							value: 'Evidence Uploaded'
						},
						{
							id: 10403,
							value: 'Completed'
						},
						{
							id: 10417,
							value: 'Not Applicable'
						},
						{
							id: 10418,
							value: 'Terminated'
						},

					]
                
				},
				{
					key:'findingCategory',
					value: [
						{
							id: 1,
							value: 'Minor'
						},
						{
							id: 2,
							value: 'Major'
						},
						{
							id: 3,
							value: 'OFI'
						},
						{
							id: 4,
							value: 'Observation'
						}
					]
				},
				{
					key:'statusCode',
					value: [
						{
							id: 10401,
							value: 'Open'
						},
						{
							id: 10402,
							value: 'In Progress'
						},
						{
							id: 10408,
							value: 'Approval'
						},
						{
							id: 10419,
							value: 'ForReview'
						},
						{
							id: 10415,
							value: 'InReview'
						},
						{
							id: 10403,
							value: 'Completed'
						},
						{
							id: 10418,
							value: 'Terminated'
						},
						
					]
				}
			],
			// actionButtonRow:{}
			
		};
	},
	components: {
		toolTip,
		pagination,
		dotsLoader
	},
	// async mounted() {
	// 	this.initialize();
	// 	emitter.on('visible', (isBoolean: any) => {
	// 		this.visibility = isBoolean;
	// 		this.visibility == false ? (this.filters = {}) : '';
	// 	});
	// },
	computed: {
		// ...mapGetters({ dotsLoading: 'dotsLoading' }),
		sortedData: function (): any {
			return [...this.tableRow]
				.filter((row: any) => {
					console.log('row', row);
					console.log(' this.filters', this.filters);
					for (const key in this.filters) {
						const inputValue = this.filters[key];
						console.log('inputValue', inputValue);
						if (inputValue !== '' && row[key]) {
							if (Number.isInteger(row[key])) {
								if (row[key] !== Number(inputValue)) {
									return false;
								}
							} else {
								if (!row[key].toLowerCase().includes(inputValue.toLowerCase())) {
									return false;
								}
							}
						}
					}
					return true;
				})
				.sort((a, b) => {
					let modifier = 1;
					if (this.currentSortDir == 'desc') modifier = -1;
					if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
					if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
					return 0;
				})
				.filter((row, index) => {
					this.dynamicPagination = index + 1;
					let start = (this.currentPage - 1) * this.pageSize;
					let end = this.currentPage * this.pageSize;
					if (index >= start && index < end) return true;
				});
		},
		columnNames: function (): any {
            const names = new Set();
            for (const key of this.headersData) {
                if (key != 'entityId' && key != '_id') names.add(key);
            }
            return names;
        },
		// actionKeys: function():any{
		// 	let startArray = [1,3,4,5,6];
		// 	let progressArr = [2,4,5,6];
		// 	let mainArr :any
		// 	if(this.actionButtonRow.auditStatus == 10401){
		// 		mainArr = startArray
		// 	}else{
		// 		mainArr = progressArr;
		// 	}
		// 	return this.optionsValue.filter((obj:any)=>{return mainArr.includes(obj.id)})
		// }
	},
	async mounted() {
		this.initialize();
		emitter.on('visible', (isBoolean: any) => {
			this.visibility = isBoolean;
			this.visibility == false ? this.filters = {}:'';
		});
		emitter.on('*', (type, booleanValue) => {
        	if (type == 'apps-sidemenubar' || type == 'toggle-sidebar') {
        		this.showDropDown == true ? (this.showDropDown = false) : '';
        	} else {
        		if (this.showBlock === true && type != 'deActiviteToolTip') {
        			this.showDropDown == true ? (this.showDropDown = booleanValue) : '';
        		} else if (this.showBlock === false) {
        			this.showDropDown == true ? '' : (this.showDropDown = booleanValue);
        			this.showBlock = true;
        		}
        	}
        });
		window.addEventListener('resize', this.handleResize);
		this.handleResize();
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.handleResize);
	},
	watch: {
		visibility(newValue) {
			if (newValue == false) {
				this.initialize();
			}
		},
		tableRowData: {
			handler(newVal, oldVal) {
				this.tableRow = this.tableRowData;
				this.initialize();
				console.log('this.tableRowData[0]', this.tableRowData[0]);
			},
			deep: true
		}
	},
	methods: {
		handleResize() {
			const currentWidth = window.innerWidth;
			// Check if the width is less than or equal to 80%
			if (currentWidth >= (window.screen.width * 0.8)) {
				this.showDropDown = false;
			this.showBlock = false // Close the dropdown
			}
			// Repeat for other widths if needed
			else if (currentWidth >= (window.screen.width * 0.7)) {
				this.showDropDown = false;
			}
			else if (currentWidth >= (window.screen.width * 0.6)) {
				this.showDropDown = false;
			}
			else if (currentWidth >= (window.screen.width * 0.5)) {
				this.showDropDown = false;
			}
		},
		calculateDropdownPosition(event:any,column:any,index:any) {
			if (column == 'actionButtonAtAudits') {
                if (this.selectedRowIndex === index) {
                    this.showDropDown = !this.showDropDown;
                } else {
                    this.showDropDown = true;
                    this.selectedRowIndex = index;
					// this.actionButtonRow = rowData
                }
            }
			this.showBlock = false;
			const { clientX, clientY } = event;

			// Calculate the desired position (you might want to adjust this logic)
			this.dynamicLeftPosition = clientX-40;
			const offset = 15; // Adjust this value as needed
            this.dynamicTopPosition = clientY + offset; // Example adjustment
			
			// Optionally, handle boundary checks here
		},
		isNearBottom(index:any) {
			if(((index == 0)||(index == 1))&&((this.sortedData.length == 1)||(this.sortedData.length == 2))){
				return false;
			}else{
				return index >= this.sortedData.length - 2;
			}
			
		},
  
		changeDateFormat(date: any) {
			if (date.includes('-')) {
				let dateParts = date.split('-');
				if (dateParts.length === 6) {
				let startDate = `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
				let endDate = `${dateParts[5]}/${dateParts[4]}/${dateParts[3]}`;
				return `${startDate} - ${endDate}`;
				} else if (dateParts.length === 3) {
				return `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
				}
			}
			return date.split('-').reverse().join('/');
		},
		getBorder(){
            return `numberBorder numberNavy`
        },
		getPaddingForColumn(column:any){
            if(column=='findingStatus'){
                return 'pl-3'
            }else if(column=='auditStatus' ){
                  return 'pl-5'
			}else if(column=='auditPeriod'){
				return 'pl-4'
			}else if(column=='taskStatus'){
                return 'pl-9'
            }
			else if(column == 'statusCode'){
                return 'pl-4'
            }
        },
		clickedOnDeleteButton(rowData:any){
            emitter.emit('deleteRowFromTable',rowData)
        },
		 colorAuditText(status: any) {
			if (status == 10401) {
				return ['font-semibold text-disabledgrey'];
			} else if (status == 10402 || status == 10415) {
				return ['font-semibold text-yellow'];
			} else if (status == 10403) {
				return ['font-semibold text-lightgreen'];
			} else if(status == 10414){
				return ['font-semibold mx-2 text-electricGreen']
			} else if(status == 10416){
				return ['font-semibold mx-2 text-primary']
			}else if(status == 10417 || status == 10418){
				return ['font-semibold mx-2 text-darkred']
			}
		},
		colorAuditBg(status: any) {
			if (status == 10401) {
				return ['bg-lightgrey border-solid border border-disabledgrey'];
			} else if (status == 10402 || status == 10415) {
				return ['bg-moderateColor border-solid border border-yellow'];
			} else if (status == 10403) {
				return ['bg-veryLowColor border-solid border border-lightgreen'];
			} else if (status == 10414) {
				return ['pendingEvidence border-solid border border-electricGreen'];
			} else if (status == 10416) {
				return ['evidenceUpload border-solid border border-primary'];
			}else if (status == 10417 || status == 10418) {
				return ['bg-veryHighColor border-solid border border-error'];
			} else {
				return '-'
			}
		},
		textColorForStatus(status: any) {
			if (status == 10403 || status == 10418) {
				return ['font-semibold text-error'];
			} else if (status == 10402 || status == 10415) {
				return ['font-semibold text-yellow'];
			} else if (status == 10401) {
				return ['font-semibold text-lightgreen'];
			} 
		},
		bgColorForStatus(status: any) {
			if (status == 10403 || status == 10418) {
				return ['bg-veryHighColor border-solid border border-error'];
			} else if (status == 10402 || status == 10415 ) {
				return ['bg-moderateColor border-solid border border-yellow'];
			} else if (status == 10401) {
				return ['bg-veryLowColor border-solid border border-lightgreen'];
			} 
		},
		textColorForFindingStatus(status: any) {
			if (status == 10403 ) {
				return ['font-semibold text-lightgreen'];
			} else if (status == 10415) {
				return ['font-semibold text-yellow'];
			} else if (status == 10401) {
				return ['font-semibold text-disabledgrey'];;
			} else if (status == 10418) {
				return ['font-semibold text-error'];;
			} else if(status == 10402){
				return ['font-semibold mx-2 text-electricGreen']
			} else if(status == 10406 || status == 10408){
				return ['font-semibold mx-2 text-primary']
			} else if(status == 10419){
				return ['font-semibold mx-2 text-forReview']
			} else {
				return '-'
			}
		},
		bgColorForFindingStatus(status: any) {
			if (status == 10403) {
				return ['bg-veryLowColor border-solid border border-lightgreen'];
			} else if (status == 10415 ) {
				return ['bg-moderateColor border-solid border border-yellow'];
			} else if (status == 10401) {
				return ['bg-lightgrey border-solid border border-disabledgrey'];
			} else if (status == 10418) {
				return ['bg-veryHighColor border-solid border border-error'];
			} else if (status == 10402) {
				return ['pendingEvidence border-solid border border-electricGreen'];
			} else if (status == 10406 || status == 10408) {
				return ['evidenceUpload border-solid border border-primary'];
			}else if (status == 10419) {
				return ['bg-forReviewBg border-solid border border-forReview'];
			} else {
				return '-'
			}
		},
		isColumnPresentForLabel(column:any){
			if(column=='auditStatus'){
				return true
			}
		},
		initialize() {
			console.log('this.tableRowData', this.tableRowData);
			this.tableRow = this.tableRowData;
			this.searchTableRow = this.tableRowData;
		},
		paginationUpdations(currentPage: any, pageSize: any) {
			console.log('Pagination processing... ', currentPage, pageSize);
			this.currentPage = currentPage;
			this.pageSize = pageSize;
		},
		sort(tableHead: any) {
			this.className = tableHead;
			if (tableHead == this.currentSort) {
				this.currentSortDir = this.currentSortDir == 'asc' ? 'desc' : 'asc';
			}
			this.currentSort = tableHead;
			this.classSort = this.currentSortDir == 'asc' ? 'sort_asc' : 'sort_desc';
		},
		openPopup(data: any, index: any) {
			let popupdata = {
				entityData: data,
				indexx: index
			};
			emitter.emit('showData123', popupdata);
		},
		toolTip(column: any, e: any, index: any, row: any, status: any) {
			this.tipText = row[column];
			this.tipColumn = column;
			this.tipIndex = index;

			switch (status) {
				case 'text':
					this.handleTextTooltip(e);
					break;
				case 'circleButton':
					if (e.offsetX < e.target.offsetWidth && e.offsetY < e.target.offsetHeight) {
						let obj:any = {
							tipText : this.tipText
						}
                       emitter.emit('activiteToolTip',obj)
					}
			}
		},
		handleTextTooltip(e: any) {
			const container = e.currentTarget.closest('.table-ellipsis');
			// console.log("tooltip",container)
			if (container && this.isTextOverflowed(container)) {
				  let obj:any = {
						tipText : this.tipText
					}
                 emitter.emit('activiteToolTip',obj)
			}
		},
		isTextOverflowed(element: any) {
			return element.scrollHeight > element.clientHeight;
		},
		doFalse() {
			emitter.emit('deActiviteToolTip')
		},
		getOwnerType(input: any) {
			let type = this.orgType.find((l: any) => {
				return l.id == input;
			});
			if (type == undefined) {
				return input;
			} else {
				return type.value;
			}
		},
		getColumnLabel(columnName: any) {
			// console.log("sbjhsabhjhdsh",columnName)
			if (columnName == 'actionButtonAtAudits' || columnName == 'deleteIcon' ){
                return '';
            }
            const column = this.columns.find((col: { key: any }) => col.key === columnName);
            return column ? column.value : columnName;
        },
		isColumnArrayPresent(columnName: any) {
            // Returns true if the column name has corresponding dropdown options
            return this.dropdownOptions.some((option: { key: any }) => option.key === columnName);
        },
		getDropdownOptionsForFilter(columnName: any) {
            // console.log('sazxsadsad', columnName);
            const option = this.dropdownOptions.find((option: { key: any }) => option.key === columnName);
            return option ? option.value : [];
        },
		getStatusLabelFromColumn(columnName: any, type: any) {
            // console.log('sazxsadsad', columnName);
            const option = this.dropdownOptions.find((option: { key: any }) => option.key === type);
            const option1 = option.value.find((soption: { id: any }) => soption.id === columnName);
			if(option1 == undefined || option1 == ''){
				return '-'
			}
            // console.log('wasbdhjbdzjsad', option);
            // console.log('wasbdhjbdzjsad', option1);
            return option ? option1.value : [];
        },
		isFilterNotNecessary(columnName:any) {
            if(columnName == 'actionButtonAtAudits'  || columnName == 'deleteinfo' || columnName == 'startDate' || columnName == 'endDate' || columnName == 'auditPeriod')
            {
                return true;
            }
			return false;
        },
		// shouldShowDisabled(columnName:any) {
        //     if(columnName == 'startDate' || columnName == 'endDate' || columnName == 'auditPeriod')
        //     {
        //         return true;
        //     }
            
        //         return false;  
        // },
		getLabelsArrayForColumnInStore(column: any){
			if(column == 'auditStatus'){
				return true
			} else{
				return false
			}
		},
		 getTeamName(ownerId: any) {
            let user = this.teamObject.find((obj: any) => {
                return obj.value == ownerId;
            });
            if (user == undefined) {
                let empty = '';
                return empty;
            } else {
                return user.label;
            }
        },
		 getLocationName(locationId: any) {
			if(locationId == 'Global'){
				return 'Global'
			}
			
            let user = this.locationObject.find((obj: any) => {
                return obj.value == locationId;
            });
            if (user == undefined) {
                let empty = 'N/A';
                return empty;
            } else {
                return user.label;
            }
        },
		getOwnerName(userId: any) {
            let user = this.optionsUserArray.find((obj: any) => {
                return obj.value == userId;
            });
            if (user == undefined) {
                let empty = 'N/A';
                return empty;
            } else {
                return user.label;
            }
        },
		checkuserData(column: any) {
            if (column == 'team' || column == 'entity' ) {
                return true;
            } else if(column == 'assignee'){
				return true;
			}
        },
		clickedOnActionButton(rowData: any, index: any, column: any) {
				emitter.emit('clickedOnTableRow',rowData)
		},
		
		optionClickedOnAction(actionOption:any,rowData:any){
			let actionObj:any = {
				action: actionOption,
				data:rowData
			}
			if(rowData['auditStatus'] != 10401 && actionOption=='Edit'){
                toast.error('Cannot Edit Audit in this Status', {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            }else if(actionOption == 'Findings'){
				this.$router.push({ name: 'auditFindings', params: { auditId: rowData._id } });
			}else{
                emitter.emit('clickedOnDropDownAction',actionObj)
            }
		},
		// optionClickedOnActionAtFindings(actionOption:any,rowData:any){
		// 	console.log('rowData',rowData)
		// 	if(actionOption == 'View'){
		// 		this.$router.push({ name: 'findingsInfo', params: { findingId: rowData._id } });
		// 	}
		// },
		// optionClickedOnActionAtActionTasks(actionOption:any,rowData:any){
		// 	console.log('rowData',rowData)
		// 	if(actionOption == 'View'){
		// 		this.$router.push({ name: 'findingTaskEdit', params: { taskId: rowData._id ,findingId : rowData.linkedFindingId} });
		// 	}
		// },
		// optionClickedOnActionAtControlsTasks(actionOption:any,rowData:any){
		// 	console.log('rowData',rowData)
		// 	if(actionOption == 'View'){
		// 		this.$router.push({ name: 'controlEdit', params: { taskId: rowData._id ,auditId : rowData.auditId} });
		// 	}
		// },
		async getAllUsers() {
            this.optionsUserArray = [];
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/users/getAll`).then((result: any) => {
                // console.log('result of getAllUsers', result.data);
                result.data.map((obj: any) => {
                    let sendObj = {
                        value: obj._id,
                        label: obj.name
                    };
                    this.optionsUserArray.push(sendObj);
                    // console.log('getAllUsersListgetAllUsersListgetAllUsersList', this.optionsUserArray)
                });
            });
        },
		 async getallTeams() {
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/departments/get`).then((res: any) => {
                this.users = res.data;
            });
            for (var i = 0; i < this.users.length; i++) {
                this.teamObject.push({ label: this.users[i].displayName, value: this.users[i]._id });
            }
        },
		 async getallEntities() {
			let result:any
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/businessEntities/getall`).then((res: any) => {
                result = res.data;
            });
            for (var i = 0; i < result.length; i++) {
                this.locationObject.push({ label: result[i].entityName, value: result[i]._id });
            }
        },
		selectInputStyling(column: any){
			if(column == 'auditStatus' || column == 'findingStatus'){
				return 'widthFor1stAudit'
			}else if(column == 'taskStatus'){
				return 'statusWidthForTask'
			}
		},
		getColumnHeadWidth(column: any) {
            let ValueKeys:any = ['frameWorkControl','taskId','CCID','findingId','frameworks']
            if(ValueKeys.includes(column)){
               return 'w-28';
            }
			let ValueKeys1:any = ['auditId','findingCategory','teamName','title','startDate','endDate','auditor1','Status','entityName','controlNumber','findingStatus']
            if(ValueKeys1.includes(column)){
               return 'w-32';
            }
			let ValueKeys2:any = ['auditName','controlName','taskStatus']
            if(ValueKeys2.includes(column)){
               return 'w-40';
            }
			// let ValueKeys3:any = ['controlName']
            // if(ValueKeys3.includes(column)){
            //    return 'w-40';
            // }
			let ValueKeys4:any = ['auditStatus','findingComment','assignee','location','department','Frequency','statusCode']
            if(ValueKeys4.includes(column)){
               return 'w-36';
            }
			let ValueKeys5:any = ['teamName1','entityName1','auditee','auditor']
            if(ValueKeys5.includes(column)){
               return 'width25';
            }


            else if( column == 'deleteIcon'){
                return 'w-20';
            }
			else if(column=='actionButtonAtAudits' ){
                return 'actionButtonWidth';
            }
			else if(column=='Title'){
				return 'w-48'
			}else if(column=='action' || column == 'auditPeriod'){
				return 'w-52'
			}else if(column == 'TaskId'){
				return 'w-24	'
			}
			// else if(column == 'title'){
			// 	return 'w-80'
			// }
			else {
                return '';
            }
        },
	},
	async created(){
        await this.getAllUsers();
		await this.getallTeams();
		await this.getallEntities();
    },
});
</script>

