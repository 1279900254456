<template>
	<div class="pt-3 px-5 pb-2">
		<div class="items-center justify-between flex ResponsiveStartAudit">
			<div class="title flex items-center">
				<h1>My Findings</h1>
			</div>
            <div>
                <button :class="tableRow.length == 0 ? 'btndisabled pointer-events-none' : 'filterButton'" class="flex" @click="filterOpen" >
                    Filters
                    <img src="@/assets/filter.svg" class="h-3 ml-2.5" />
                </button>
			</div>
		</div>
		<div class="mt-5 ResponsiveStartAudit">
			<p class="headerText">
				This course helps you to Understand current data protection legislation and underlying principles. It also helps you to Understand what Privacy Awareness is, what PII is, PII Processing, Individual Rights, Cross Border Transfers, PII Breaches, Privacy By Design. This course helps you
				to understand what Information Security is, awareness on information security, different kinds of security breaches happening in daily life, physical security, email security, Knowing your responsibilities when dealing with Information Security.
			</p>
		</div>
        <!-- {{  tableRow  }} -->
        <div>
			<tableData :tableRowData="tableRow" :headersData="mainHeaders" :loadData="dataLoading"></tableData>
            <div v-if="toolTipActive">
				<tool-tip :tipText="toolTipText"></tool-tip>
			</div>
		</div>
         <!-- <popup v-if="showPopUp" :popUp="showPopUp">
			<template v-slot:header>
				<div class="flex items-center justify-between m-4 rounded">
					<h1>Finding</h1>
					<button class="float-right text-3xl" @click="onCancelButtonAddPopUp()">
						<img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
					</button>
				</div>
			</template>
			<fieldset>
				<div class="statuscontainer popupbodycontainer">
					<div class="formcontainer flex flex-col">
                        <div class="row flex flex-row w-full mt-3">
							<div class="w-1/2 pr-2">
								<label class="controllabel ">Finding-Id</label>
                                 <div class="text-sm mt-2">{{this.ActionObj.findingId}}</div>
							</div>
							<div class="w-1/2 pr-2">
								<label class="controllabel ">Finding Category</label>
                                 <select class="text-sm mt-2 custom-select" disabled v-model="this.ActionObj.findingCategory">
									<option value="" selected disabled hidden>Please select one</option>
									<option v-for="i in findingCategoryData" :value="i.id" :key="i.id">
										{{ i.value }}
									</option>
								</select>
							</div>
						</div>
						<div class="row flex flex-row w-full mt-3">
							<div class="w-1/2 pr-2">
								<label class="controllabel ">Framework</label>
                                 <div class="text-sm mt-2">{{this.ActionObj.frameworks}}</div>
							</div>
							<div class="w-1/2 pr-2">
								<label class="controllabel ">Control Number</label>
                                 <div class="text-sm mt-2">{{this.ActionObj.frameWorkControl}}</div>
							</div>
						</div>
						<div class="w-full mt-3">
							<label class="controllabel ">Findings</label>
                            <div class="text-sm mt-2">{{this.ActionObj.findingComment}}</div>
						</div>
						<div class="w-full mt-3">
							<label class="controllabel ">Evidence</label>
                            <div class="text-sm mt-2">{{this.ActionObj.findingEvidence}}</div>
						</div>
						<div class="w-1/2 mt-3">
							<label class="controllabel ">Control Audit Result</label>
                             <div class="text-sm mt-2">{{this.ActionObj.auditResult}}</div> 
                             <div class="mt-2">
                                <div v-if="this.ActionObj.auditResult === 'Pass'" class="flex items-center justify-center rounded h-5 w-28 text-lightgreen bg-veryLowColor border-solid border border-lightgreen">{{this.ActionObj.auditResult}}</div>
                                <div v-if="this.ActionObj.auditResult === 'Fail'" class="flex items-center justify-center rounded h-5 w-28 text-darkred bg-veryHighColor border-solid border border-darkred ">{{this.ActionObj.auditResult}}</div>
                            </div>
						</div>
					</div>
				</div>
			</fieldset>
		</popup> -->

	</div>
</template>
<style scoped>
.custom-select {
    background-image: url("../assets/Collapse.svg") !important;
    background-repeat: no-repeat no-repeat;
    background-position: center center;
    background-size: 12px 12px;
    background-position: right 15px center; /* Change the px value here to change the distance */
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
}
</style>
<script lang="ts">
import { defineComponent } from 'vue';
import _ from 'lodash';
import { mapGetters } from 'vuex';
import { emitter, toast } from '@/main';
import tableData from '@/components/tableData.vue';
import popup from '@/components/popup.vue';
import toolTip from '@/components/toolTip.vue'

export default defineComponent({
	data(): any {
		return {
			showFilters: false,
            visibility: false,
            filters: {},
            orgFrameWorks:[],
            presentAuditFrameWorks:[],
            tableRow:[],
            mainHeaders:[],
			dummyList:[],
            teamObject:[],
            locationObject:[],
            activeButton:'',
            addControlPopup:false,
            myFindingsArray:[],
            savedAuditSets:[],
            teamsData:[],
            showPopUp : false,
            ActionObj : {
				frameworks: '',
				findingCategory: '',
				findingComment: '',
				findingEvidence: '',
				auditResult: '',
                findingId : '',
            },
            findingCategoryData: [
				{
					id: 1,
					value: 'Minor'
				},
				{
					id: 2,
					value: 'Major'
				},
				{
					id: 3,
					value: 'Oppurtunity For Improvement'
				},
				{
					id: 4,
					value: 'Observation'
				}
			],
            toolTipActive:false,
			toolTipText:'',
            dataLoading:true

		};
	},
	components: {
        tableData,
        popup,
        toolTip
	},
	computed: {
		...mapGetters({ userInfo: 'userInfo' }),
	},
	methods: {
        filterOpen() {
			this.showFilters = !this.showFilters;
			if (this.showFilters == true) {
                this.dummyList = this.tableRow;
            } else {
                this.tableRow = this.dummyList;
            }
			emitter.emit('visible', this.showFilters);
		},
        async getAllTeams() {
            this.teamObject = []
            let users:any
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/departments/get`).then((res: any) => {
                users = res.data;
                this.teamsData = res.data;
            });
            for (var i = 0; i < users.length; i++) {
                this.teamObject.push({ label: users[i].displayName, value: users[i]._id });
            }
        },
		 async getallEntities() {
            this.locationObject = []
			let result:any
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/businessEntities/getall`).then((res: any) => {
                result = res.data;
            });
            for (var i = 0; i < result.length; i++) {
                this.locationObject.push({ label: result[i].entityName, value: result[i]._id });
            }
            console.log("locationObject",this.locationObject)
        },
        async getFindingsForPerson(){
            let auditorPayload:any = {
                "type":1,
                "auditor": this.userInfo._id
            }
            this.myFindingsArray = [];
            try{
                 await this.$http.post(`${process.env.VUE_APP_AUDIT_API_URL}/api/org/findings/getall/byroles`,auditorPayload).then((result: any) => {
                   this.myFindingsArray = result.data;
                });
                console.log('myFindingsArray',this.myFindingsArray)
            }catch{
                toast.error(`error`, {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            }
        },
        async getAuditSets() {
			this.savedAuditSets = [];

			await this.$http.get(`${process.env.VUE_APP_AUDIT_API_URL}/api/org/audits/getAll`).then((result: any) => {
                this.savedAuditSets = result.data
				console.log('savedAuditSets', this.savedAuditSets);
			})
		},

        async getDetailsForTable(){

            this.tableRow = [];
            this.myFindingsArray.map((findingObj:any)=>{
                let presentAudit = this.savedAuditSets.find((obj:any)=>{return obj._id == findingObj.auditId});

                let presentLocation:any
            
                let presentteamObject:any = this.teamsData.find((obj: any) => {
                    return obj._id == findingObj.department;
                });

                if (presentteamObject != undefined && presentteamObject.entityType == 20301){
                    presentLocation = 'Global';
                }else if(presentteamObject != undefined && presentteamObject.entityType == 20303){
                    presentLocation = presentteamObject.entityName
                }

                findingObj.entityName = presentLocation != undefined ? presentLocation : 'N/A';
                findingObj.teamName = presentteamObject.displayName != undefined ? presentteamObject.displayName : 'N/A';
                findingObj.auditName = presentAudit.title != undefined ? presentAudit.title : 'N/A';
                findingObj.findingStatus = findingObj.statusCode != undefined ? findingObj.statusCode : 'N/A';
                this.tableRow.push(findingObj)
            })
        },
        onclickTableRowData(data:any){
            this.showPopUp = true;
        },
        onCancelButtonAddPopUp() {
			this.showPopUp = false;
		},
        goToFindingsInfo(findingId:any){
            this.$router.push({ name: 'findingsInfo', params: { findingId: findingId} });
        },

	},

	async created() {
        emitter.off('clickedOnDropDownAction')
        this.dataLoading = true;

        let headersData = ['findingId','findingComment','auditName','teamName','entityName','findingCategory','findingStatus']

        this.mainHeaders = headersData;

        await this.getAllTeams();
        await this.getallEntities();
        await this.getAuditSets()

        await this.getFindingsForPerson()

        await this.getDetailsForTable();

        this.dataLoading = false
    },
    
    async mounted(){
        emitter.on('visible', (isBoolean: any) => {
			this.visibility = isBoolean;
			this.visibility == false ? this.filters = {}:'';
		});
        emitter.off('clickedOnTableRow')
		emitter.on('clickedOnTableRow',async (actionObj:any)=>{
            this.goToFindingsInfo(actionObj._id)
		})
		// emitter.on('clickedOnDropDownAction',async (actionObj:any)=>{
        //     this.ActionObj.frameworks = actionObj.frameworks;
        //     this.ActionObj.findingCategory = actionObj.findingCategory;
        //     this.ActionObj.findingComment = actionObj.findingComment;
        //     this.ActionObj.findingEvidence = actionObj.findingEvidence;
        //     this.ActionObj.auditResult = actionObj.auditResult;
        //      this.ActionObj.findingId = actionObj.findingId;
        //      console.log('ActionObj',this.ActionObj)
        //     if(actionObj.frameWorkControl != undefined){
        //         this.ActionObj.frameWorkControl = actionObj.frameWorkControl
        //     }else{
        //         this.ActionObj.frameWorkControl = ''
        //     }
        //     this.onclickTableRowData();
		// })

        emitter.off('activiteToolTip');
        emitter.on('activiteToolTip', (data: any) => {                        
            this.toolTipActive = true;
            this.toolTipText = data.tipText
        });
 
        emitter.off('deActiviteToolTip');
        emitter.on('deActiviteToolTip', (data: any) => {
            this.toolTipActive = false;
            this.toolTipText = ''
        });
       
    }
});
</script>
